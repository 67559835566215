<template>
  <section class="columns-collection">
    <div v-if="(isMobile && showDetail) || !isMobile" class="columns-products">
      <div v-for="(product, index) in products" :key="index" class="columns is-mobile">
        <div class="column is-7 column-name">
          <p>
            {{ product.name }} <strong>X {{ product.quantity }}</strong>
          </p>
        </div>
        <div class="column has-text-right column-price">
          <p v-if="hasDiscount(product)">
            {{ $currencyFormatter(individualPriceWithDiscountAndTaxes(product)) }}
          </p>
          <p
            :class="{
              'price-defore-discount': hasDiscount(product)
            }"
          >
            {{ $currencyFormatter(individualPriceWithTaxes(product)) }}
          </p>
        </div>
      </div>
    </div>
    <div class="footer-invoice">
      <div v-if="shouldShowCouponSection" class="footer-invoice__coupon-section">
        <CouponSection :current-step="step" />
      </div>
      <div class="footer-invoice__pricing-section">
        <div v-if="(isMobile && showDetail) || !isMobile" class="data">
          <div v-if="hasNoIvaDay" class="warningHasNoIvaDays">
            <h5>¡Ten presente!</h5>
            <p>
              Solo se cobrarán sin IVA <span>3 unidades</span> por categoría, si adquiriste una
              cantidad mayor tu factura llegará por un valor más alto.
            </p>
          </div>

          <div class="invoice-amount-field">
            <div>Subtotal</div>
            <div>
              {{ $currencyFormatter(subtotal) }}
            </div>
          </div>

          <div v-if="appliedCoupon" class="invoice-amount-field">
            <div>Descuento {{ appliedCoupon.code }}</div>
            <div>-{{ $currencyFormatter(appliedCouponDiscountedPrice) }}</div>
          </div>

          <div v-if="shouldShowShippingCost" class="invoice-amount-field">
            <div>Costo de envío</div>
            <div>
              <span v-if="typeof validTotalShipping === 'number'">
                {{ $currencyFormatter(validTotalShipping) }}
              </span>
              <span v-else>---</span>
            </div>
          </div>

          <div class="columns is-mobile total">
            <div class="column">Total</div>
            <div class="column has-text-right">
              {{ $currencyFormatter(validTotal) }}
            </div>
          </div>
        </div>
        <info-banner v-if="infoBannerTxt" :text="infoBannerTxt" />
        <div>
          <button
            class="button is-fullwidth button-next"
            :class="buttonClasses"
            :disabled="shouldDisableButton"
            @click="nextStep"
          >
            <span>{{ textButtonNext }}</span>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { get } from 'vuex-pathify'
import store from '@/store'

import CouponSection from './CouponSection'
import InfoBanner from '@/components/shared/InfoBanner'

import { ALLOWED_PAYMENT_METHODS, TYPES_PAYMENT_ATTEMPT } from '@/utils/constants'

const { PAYU, MERCADO_PAGO, NEQUI, GREENPAY, PAYPAL, WHATSAPP } = ALLOWED_PAYMENT_METHODS

export default {
  name: 'Invoice',
  components: {
    CouponSection,
    InfoBanner
  },
  props: {
    step: { type: String, required: true },
    loadingNext: { type: Boolean, required: true },
    showDetail: { type: Boolean, required: false }
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      loadingData: false,
      infoBannerTxt: ''
    }
  },
  computed: {
    ...mapState('shop', ['shop']),
    ...mapState('shipping', ['loadingShipping']),
    ...mapState('cart', ['productsInCart', 'paymentMethod', 'shippingMethod']),
    ...mapState('order', ['lastOrder', 'openedExternalPayment', 'validatingPayment']),
    ...mapState('coupons', ['appliedCoupon']),
    ...mapGetters('config', ['mode']),
    ...mapGetters('cart', ['subtotal', 'appliedCouponDiscountedPrice']),
    ...mapGetters('shipping', ['totalShipping']),
    ...mapGetters('app', ['isMobile']),
    hasNoIvaDay: get('shop@shop.config.hasNoIvaDay'),
    declinedPayment: get('shop@shop.declinedPayment'),
    textButtonNext() {
      const { step, lastOrder, openedExternalPayment, validatingPayment } = this

      switch (step) {
        case 'CURRENT_PRODUCTS':
          return 'INICIAR COMPRA'
        case 'CONFIRM_SUMMARY':
          if (this.paymentMethod?.provider === PAYPAL) {
            return 'CONTINUAR'
          }

          return 'CONFIRMAR PEDIDO'
        case 'PAYPAL_PAYMENT':
          return 'CONFIRMAR PEDIDO'

        case 'CONFIRM_SUCCESS': {
          const { provider } = lastOrder.paymentMethod

          if (provider === WHATSAPP) {
            return 'ENVIAR PEDIDO VÍA WHATSAPP'
          } else if ([PAYU, MERCADO_PAGO, GREENPAY].includes(provider)) {
            return openedExternalPayment ? 'VERIFICANDO...' : 'REDIRIGIENDO...'
          } else if (provider === NEQUI) {
            return validatingPayment ? 'VERIFICANDO PAGO...' : 'VERIFICAR PAGO DE NEQUI'
          } else {
            return 'SEGUIR COMPRANDO'
          }
        }
        case 'CHECKOUT_PAYMENT': {
          const { provider: lastOrderPaymentProvider, status } = lastOrder.payments[0]

          if (lastOrderPaymentProvider === NEQUI && status === 'PENDING') {
            return 'REINTENTAR PAGO'
          }

          return 'SEGUIR COMPRANDO'
        }
        default:
          return 'CONTINUAR'
      }
    },
    products() {
      if (this.lastOrder && (this.step === 'CONFIRM_SUCCESS' || this.step === 'CHECKOUT_PAYMENT')) {
        return this.lastOrder.items
      } else {
        return this.productsInCart
      }
    },
    validTotalShipping() {
      if (this.lastOrder && (this.step === 'CONFIRM_SUCCESS' || this.step === 'CHECKOUT_PAYMENT')) {
        return this.lastOrder.shippingCost || 0
      }
      return this.totalShipping
    },
    validTotal() {
      if (this.lastOrder && (this.step === 'CONFIRM_SUCCESS' || this.step === 'CHECKOUT_PAYMENT')) {
        return this.lastOrder.total
      }

      const result = this.$Big(this.subtotal)
        .minus(this.$Big(this.appliedCouponDiscountedPrice))
        .plus(this.validTotalShipping)

      return this.$formatCurrencyForCalculations(result)
    },
    shouldShowShippingCost() {
      return this.step !== 'CURRENT_PRODUCTS' && this.step !== 'DATA_CONTACT'
    },
    isCheckoutDisabledByDeclinedPayment() {
      return this.declinedPayment === TYPES_PAYMENT_ATTEMPT.FIFTH_ATTEMPT
    },
    isConfirmDisabledByPreviewMode() {
      return this.mode === 'preview' && this.step === 'CONFIRM_SUMMARY'
    },
    shouldDisableButton() {
      const { loadingNext, step, productsInCart, lastOrder, validatingPayment } = this
      const isLastOrderNull = !lastOrder || !lastOrder.paymentMethod
      const { provider } = isLastOrderNull ? {} : lastOrder.paymentMethod

      if (
        loadingNext ||
        (step === 'CURRENT_PRODUCTS' && !productsInCart.length) ||
        (step === 'PAYMENT_METHOD' && (!this.paymentMethod || !this.shippingMethod)) ||
        (step === 'CONFIRM_SUCCESS' && [PAYU, MERCADO_PAGO, GREENPAY].includes(provider)) ||
        (step === 'PAYPAL_PAYMENT' && this.paymentMethod?.provider === PAYPAL) ||
        validatingPayment
      ) {
        return true
      }

      return false
    },
    buttonClasses() {
      return {
        'is-loading': this.loadingNext || this.loadingData,
        'is-whatsapp':
          this.lastOrder &&
          this.step === 'CONFIRM_SUCCESS' &&
          this.lastOrder.paymentMethod?.provider === WHATSAPP,
        'is-disabled': this.isCheckoutDisabledByDeclinedPayment || this.isConfirmDisabledByPreviewMode
      }
    },
    shouldShowCouponSection() {
      if (!this.products.length) return false

      if (this.appliedCoupon) return true

      const hideCouponSteps = ['CONFIRM_SUMMARY', 'CONFIRM_SUCCESS']

      if (hideCouponSteps.includes(this.step)) return false

      return true
    },
    queryParams() {
      const query = {}
      if (this.mode) query.mode = this.mode
      return query
    }
  },
  methods: {
    ...mapActions('products', ['checkInventory']),
    individualPriceWithDiscountAndTaxes(product) {
      return this.$formatCurrencyForCalculations(
        this.$Big(product.finalPrice).times(product.quantity)
      )
    },
    individualPriceWithTaxes(product) {
      return this.$formatCurrencyForCalculations(
        this.$Big(product.finalPriceBeforeDiscounts).times(product.quantity)
      )
    },
    hasDiscount(product) {
      return (
        this.individualPriceWithDiscountAndTaxes(product) !== this.individualPriceWithTaxes(product)
      )
    },
    async checkInventoryAvailability() {
      const someProductHasInsufficientStock = await this.checkInventory(this.products)

      return someProductHasInsufficientStock
    },
    async nextStep() {
      if (this.isCheckoutDisabledByDeclinedPayment) {
        this.infoBannerTxt = 'En este momento no es posible realizar la compra, inténtalo de nuevo más tarde.'
        return
      }
      if (this.isConfirmDisabledByPreviewMode) {
        this.infoBannerTxt = 'La vista previa de la tienda no permite generar ordenes de compra.'
        return
      }

      const checkForStock = this.step === 'CURRENT_PRODUCTS' || this.step === 'CONFIRM_SUMMARY'

      if (checkForStock) {
        this.loadingData = true
        const someProductHasInsufficientStock = await this.checkInventoryAvailability()
        this.loadingData = false

        if (someProductHasInsufficientStock) {
          this.$emit('go-to-start', { step: 'CURRENT_PRODUCTS' })

          if (this.step === 'CONFIRM_SUMMARY') {
            store.set('cart/showNoStockWarning', true)
          }

          return
        }
      }

      this.$emit('changeShowDetail', false)
      store.set('cart/showNoStockWarning', false)

      if (this.step !== 'CONFIRM_SUMMARY' && this.step !== 'CONFIRM_SUCCESS') {
        try {
          window.scrollTo({ top: 0, behavior: 'smooth' })
        } catch (e) {}
      }

      let step = null
      if (this.step === 'CURRENT_PRODUCTS') {
        step = 'DATA_CONTACT'
      } else if (this.step === 'DATA_CONTACT') {
        step = 'PAYMENT_METHOD'
      } else if (this.step === 'PAYMENT_METHOD') {
        step = 'CONFIRM_SUMMARY'
      } else if (this.step === 'CONFIRM_SUMMARY' || this.paymentMethod?.provider === PAYPAL) {
        step = 'CONFIRM_SUCCESS'
      }

      if (step) {
        this.$emit('nextStep', { step })
      } else {
        if (this.step === 'CONFIRM_SUCCESS') {
          if (this.lastOrder && this.lastOrder.paymentMethod?.provider === WHATSAPP) {
            window.open(this.lastOrder.paymentLink)
          } else if (this.lastOrder && this.lastOrder.paymentMethod?.provider === NEQUI) {
            store.set('order/validatingPayment', true)
            setTimeout(() => {
              window.open(
                `/checkout/orders/${this.lastOrder.id}/payment/${this.lastOrder.payments[0].id}?provider=NEQUI`,
                '_self'
              )
            }, 4000)
          } else {
            this.$router.push({ name: 'home', query: this.queryParams })
          }
        } else if (this.step === 'CHECKOUT_PAYMENT') {
          if (
            this.lastOrder.payments[0]?.provider === NEQUI &&
            this.lastOrder.payments[0]?.status === 'PENDING'
          ) {
            this.$emit('nextStep', { step: 'CONFIRM_SUCCESS' })
          } else {
            this.$router.push({ name: 'home', query: this.queryParams })
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.columns {
  font-size: 12px;
  line-height: 18px;
  color: #47536b;
  margin-top: 14px;
}
.column-name {
  margin-left: 24px;
}
.column-price {
  margin-right: 29px;
  .price-defore-discount {
    text-decoration-line: line-through;
    margin-top: -3px;
    color: #c2c9d6;
    font-size: 11px;
  }
}
.columns-collection {
  height: calc(100% - 66px);
  min-height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  position: relative;
}
.footer-invoice {
  padding: 18px 0 24px;
  bottom: 0;
  width: 100%;

  &__coupon-section {
    margin-bottom: 30px;
  }

  &__pricing-section {
    padding: 0 25px;
  }
}
.button-next {
  margin-top: 15px;
  background: var(--color-tertiary);
  color: #fff;
  font-size: 18px;
  border-radius: 0;
  line-height: 21px;
  text-transform: uppercase;
  height: 50px;
  img {
    margin-left: 10px;
  }
}
.button-next.is-whatsapp {
  background: #58ca63;
  margin-bottom: 10px;
}
.footer-invoice .columns {
  margin: 0;
}
.footer-invoice .column {
  padding: 0;
}
.warningHasNoIvaDays {
  background: #ffffff;
  border: 1px solid #e4e9f6;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px 20px 13px 10px;
  margin-bottom: 13px;
  h5 {
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;
    color: #47536b;
    margin-bottom: 3px;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    color: #47536b;
    span {
      color: #00b19d;
    }
  }
}
.invoice-amount-field {
  font-size: 14px;
  line-height: 16px;
  color: #707070;
  font-weight: 500;
  margin-bottom: 7.5px !important;
  display: flex;
  justify-content: space-between;
}
.total {
  font-size: 18px;
  line-height: 21px;
  color: #4d4d4d;
  font-weight: 500;
  margin-top: 17.5px !important;
  margin-bottom: 15px !important;
}

@media (max-width: 768px) {
  .columns-collection {
    min-height: 100px;
  }
  .columns {
    margin-top: 0;
    margin-bottom: 0;
  }
  .column-name {
    padding-bottom: 0;
  }
}
</style>
