<template>
  <section>
    <div v-if="paymentMethod" class="container-item payment">
      <div class="header">
        <span class="icon">
          <img src="@/assets/icon-dollar.svg">
        </span>
        <h2>INFORMACIÓN DE PAGO</h2>
      </div>
      <div class="body">
        <div class="columns is-mobile">
          <div class="column is-1">
            <span class="icon" :class="paymentMethod.provider ? paymentMethod.provider.toLowerCase() : '' ">
              <img :src="iconPayment">
            </span>
          </div>
          <div class="column is-11 has-text-left container-text">
            <h2>{{ paymentMethod.name }}</h2>
            <p class="additional-name">Al confirmar tu pedido recibirás instrucciones para realizar el pago.</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="client" class="container-item contact">
      <div class="header">
        <span class="icon">
          <img src="@/assets/icon-user.svg">
        </span>
        <h2>DATOS DE CONTACTO</h2>
      </div>
      <div class="body">
        <div class="columns">
          <div class="column has-text-left">
            <span>{{ client.name }}</span>
          </div>
        </div>
        <div class="columns">
          <div class="column has-text-left">
            <span>{{ client.email }}</span>
          </div>
          <div class="column">
            <span>{{ client.phone }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="address" class="container-item shipping">
      <div class="header">
        <span class="icon">
          <img src="@/assets/icon-truck.svg">
        </span>
        <h2>DATOS DE ENVÍO</h2>
      </div>
      <div class="body">
        <div class="columns">
          <div class="column has-text-left">
            <span>{{ address.address }}</span>
          </div>
          <div class="column">
            <span>{{ address.city }}</span>
          </div>
        </div>
        <div v-if="address.zipcode" class="columns">
          <div class="column has-text-left">
            <span>{{ address.zipcode }}</span>
          </div>
        </div>
        <div v-if="address.additionalInfo" class="columns">
          <div class="column has-text-left">
            <span>{{ address.additionalInfo }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-center terms">
      <p>
        Al confirmar tu pedido, aceptas los
        <span class="link" @click="goTerms()">Términos y condiciones de Alegra Tienda.</span>
      </p>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('cart', ['client', 'address']),
    ...mapState('cart', ['paymentMethod']),
    iconPayment() {
      let icon = require('@/assets/icon-payment-generic.svg')

      if (this.paymentMethod.provider) {
        icon = require(`@/assets/logo-${this.paymentMethod.provider.toLowerCase()}.png`)
      } else if (this.paymentMethod.logoFiles) {
        icon = this.paymentMethod.logoFiles.url
      }

      return icon
    }
  },
  methods: {
    goTerms() {
      window.open('/terms-and-conditions', '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
  .container-item{
    width: 100%;
    background-color: #F4F5FB;
    border: 1px solid var(--color-border-box);
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 17px 17px 28px 15px;
  }
  .header {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    color: #8592AD;
    margin-bottom: 18px;
  }
  .header .icon {
    margin-right: 12px;
  }
  .icon.visanet {
    width: 40px;
  }
  .icon.greenpay {
    width: 33px;
  }
  .body {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #29303D;
  }
  .additional-name {
    margin-top: 3px;
    font-size: 11px;
    color: #8592ad;
    text-transform: none;
  }
  .columns{
    align-items: center;
  }
  .terms {
    margin-top: 25px;
    margin-left: 3px;
    p {
      font-size: 12px;
      .link {
        color: #00b19d;
      }
    }
  }
@media (max-width: 768px) {
  .payment {
    .container-text {
      padding-left: 25px;
    }
  }
}
</style>
