<template>
  <div>
    <div class="bar" :class="this.status.toLowerCase()"></div>
    <div class="container-checkout">
      <div class="container-icon" :class="this.status.toLowerCase()">
        <img class="icon-success" :src="require(`@/assets/${statusIcon}`)">
        <h3 class="status-text" :class="this.status.toLowerCase()">{{ statusText }}</h3>
      </div>
      <h4 class="number-order-text">NÚMERO DE PEDIDO <strong>{{lastOrder.number}}</strong></h4>
      <div v-if="this.status == 'APPROVED'" class="container-secondary-text">
        <p class="secondary-text">Hemos enviado el comprobante de pago al correo</p>
        <p class="secondary-text">{{ lastOrder.client.email }}</p>
      </div>
      <div v-if="this.status == 'PENDING'" class="container-secondary-text">
        <p class="secondary-text">Enviaremos el comprobante de pago al correo</p>
        <p class="secondary-text">{{ lastOrder.client.email }}</p>
      </div>
      <div v-if="this.status == 'REJECTED'" class="container-secondary-text">
        <p class="secondary-text">Ha ocurrido un error al intentar realizar el pago de tu pedido,</p>
        <p class="secondary-text"><strong>{{ statusCause }}</strong></p>
        <p class="secondary-text">te recomendamos intentarlo nuevamente o ponerte</p>
        <p class="secondary-text">en contacto al teléfono {{shop.contactInfo.phone}}</p>
      </div>
      <div v-if="this.status == 'FAILED'" class="container-secondary-text">
        <p class="secondary-text">Ha ocurrido un error al intentar crear el pago de tu pedido,</p>
        <p class="secondary-text">te recomendamos intentarlo nuevamente o ponerte</p>
        <p class="secondary-text">en contacto al teléfono {{shop.contactInfo.phone}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  data() {
    return {
      status: '',
      statusCause: ''
    }
  },
  created() {
    if (['MERCADO_PAGO', 'GREENPAY'].includes(this.lastOrder.payments?.[0]?.provider)) {
      this.status = this.lastOrder.payments[0].status
    } else if (['PAYU', 'VISANET'].includes(this.lastOrder.payments?.[0]?.provider)) {
      const statusQuery = this.$route.query.status
      const dateQuery = this.$route.query.date
      const statusCauseQuery = this.$route.query.statusCause
      if (statusQuery && dateQuery && statusCauseQuery) {
        this.statusCause = statusCauseQuery
        const code = window.CryptoJS.AES.decrypt(statusQuery, dateQuery)
        this.status = code.toString(window.CryptoJS.enc.Utf8)
        if (!this.status) {
          this.$router.push({ name: 'home', query: this.queryParams })
        }
      } else {
        this.status = this.lastOrder.payments[0].status
      }
    } else if (this.lastOrder.payments && this.lastOrder.payments[0].provider === 'NEQUI') {
      this.status = this.lastOrder.payments[0].status === 'PENDING' ? 'REJECTED' : this.lastOrder.payments[0].status
    }

    if (this.status === 'CANCELED' || this.status === 'EXPIRED') {
      this.status = 'REJECTED'
    }
  },
  computed: {
    ...mapState('order', ['lastOrder']),
    ...mapState('shop', ['shop']),
    ...mapGetters('config', ['mode']),
    statusText() {
      switch (this.status) {
        case 'APPROVED':
          return '¡Pago exitoso!'
        case 'PENDING':
          return 'Pago pendiente de confirmación'
        case 'REJECTED':
          return 'Error al procesar tu pago'
        case 'FAILED':
          return 'Error al crear pago'
        default:
          return ''
      }
    },
    statusIcon() {
      switch (this.status) {
        case 'APPROVED':
          return 'icon-success.svg'
        case 'PENDING':
          return 'icon-warning.svg'
        case 'REJECTED':
          return 'icon-danger.svg'
        case 'FAILED':
          return 'icon-danger.svg'
        default:
          return 'icon-success.svg'
      }
    },
    queryParams() {
      const query = {}
      if (this.mode) query.mode = this.mode
      return query
    }
  }
}
</script>

<style lang="scss" scoped>
.bar {
  height: 10px;
}
.bar.approved {
  background-color: #AAD572;
}
.bar.pending {
  background-color: #FFD772;;
}
.bar.rejected {
  background-color: #FF8B73;
}
.container-checkout {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .container-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #FAFFF5;
    border-bottom: 1px solid #F0F4EB;
    margin-bottom: 20px;
  }
  .container-icon.approved {
    background-color: #FAFFF5;
  }
  .container-icon.pending {
    background-color: #FEFAEA;;
  }
  .container-icon.rejected {
    background-color: #FFF2F3;
  }
  .container-icon.failed {
    background-color: #FFF2F3;
  }
  .icon-success {
    width: 93px;
    height: 93px;
    margin-top: 45px;
  }
  .status-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #95C072;
    margin-top: 29px;
    margin-bottom: 50px;
  }
  .status-text.approved {
    color: #95C072;
  }
  .status-text.pending {
    color: #DFA754;
  }
  .status-text.rejected {
    color: #E85E42;
  }
  .status-text.failed {
    color: #E85E42;
  }
  .number-order-text {
    font-size: 16px;
    line-height: 24px;
    color: #47536B;
    margin-bottom: 10px;
  }
  .container-secondary-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    .secondary-text {
      font-size: 14px;
      line-height: 24px;
      color: #8592AD;
    }
  }
}
</style>
