var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "variant-product"
  }, [_vm._l(_vm.variants, function (variant, indexVariant) {
    var _vm$variantsSelected$;
    return _c('div', {
      key: indexVariant
    }, [_c('h3', {
      staticClass: "label-detail"
    }, [_vm._v(_vm._s(variant.name))]), _c('app-selector', {
      attrs: {
        "disabledIsSelectable": true,
        "options": variant.options.map(function (_ref) {
          var id = _ref.id,
            value = _ref.value,
            disabled = _ref.disabled;
          return {
            value: id,
            label: value,
            disabled: disabled
          };
        }),
        "value": ((_vm$variantsSelected$ = _vm.variantsSelected[indexVariant]) === null || _vm$variantsSelected$ === void 0 ? void 0 : _vm$variantsSelected$.idOption) || ''
      },
      on: {
        "change": function (optionId) {
          return _vm.changeSelect({
            optionId: optionId,
            variantIndex: indexVariant,
            variantId: variant.id
          });
        }
      }
    })], 1);
  }), _c('div', {
    staticClass: "error-message"
  }, [_vm.errorMessage ? _c('span', [_vm._v(_vm._s(_vm.errorMessage))]) : _vm._e()])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }