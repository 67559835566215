var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "shipping-option",
    class: {
      'shipping-option--selected': _vm.selected
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('select-shipping', _vm.id);
      }
    }
  }, [_c('div', {
    staticClass: "shipping-option__section"
  }, [_c('strong', {
    staticClass: "shipping-option__name"
  }, [_vm._v(_vm._s(_vm.name))]), _vm.description ? _c('p', {
    staticClass: "shipping-option__description"
  }, [_vm._v(_vm._s(_vm.description))]) : _vm._e()]), _c('div', {
    staticClass: "shipping-option__section"
  }, [_c('strong', {
    staticClass: "shipping-option__price"
  }, [_vm._v(_vm._s(_vm.$currencyFormatter(_vm.price)))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }