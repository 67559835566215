<template>
  <div class="section">
    <NoStockBanner v-if="showNoStockWarning" />
    <banner-has-no-iva-days />
    <app-loading v-if="loadingCheckout" :loading="true"></app-loading>
    <div v-else class="columns container-layout">
      <div class="column is-two-thirds column-main">
        <div class="level header-cart">
          <div
            class="level-item"
            :class="step === 'CURRENT_PRODUCTS' ? 'step-active' : 'step-inactive'"
          >
            <h2 class="subtitle-cart">
              <span class="text-long">PEDIDO ACTUAL</span>
              <span class="text-short mobile">1</span>
              <span class="icon">
                <img v-if="step === 'CURRENT_PRODUCTS'" src="@/assets/icon-arrow-right-black.svg" />
                <img v-else src="@/assets/icon-arrow-right-gray.svg" />
              </span>
            </h2>
          </div>
          <div
            class="level-item"
            :class="step === 'DATA_CONTACT' ? 'step-active' : 'step-inactive'"
          >
            <h2 class="subtitle-cart">
              <span class="text-long">DATOS DE CONTACTO</span>
              <span class="text-short mobile">2</span>
              <span class="icon">
                <img v-if="step === 'DATA_CONTACT'" src="@/assets/icon-arrow-right-black.svg" />
                <img v-else src="@/assets/icon-arrow-right-gray.svg" />
              </span>
            </h2>
          </div>
          <div
            class="level-item"
            :class="step === 'PAYMENT_METHOD' ? 'step-active' : 'step-inactive'"
          >
            <h2 class="subtitle-cart">
              <span class="text-long">MÉTODO DE PAGO</span>
              <span class="text-short mobile">3</span>
              <span class="icon">
                <img v-if="step === 'PAYMENT_METHOD'" src="@/assets/icon-arrow-right-black.svg" />
                <img v-else src="@/assets/icon-arrow-right-gray.svg" />
              </span>
            </h2>
          </div>
          <div
            class="level-item"
            :class="
              step === 'CONFIRM_SUMMARY' ||
              step === 'CONFIRM_SUCCESS' ||
              step === 'CHECKOUT_PAYMENT'
                ? 'step-active'
                : 'step-inactive'
            "
          >
            <h2 class="subtitle-cart">
              <span class="text-long">CONFIRMACIÓN</span>
              <span class="text-short mobile">4</span>
            </h2>
          </div>
        </div>
        <div class="main-cart">
          <app-products-in-cart v-if="step === 'CURRENT_PRODUCTS'" />
          <app-order-shipping v-show="step === 'DATA_CONTACT'" />
          <app-order-payment v-if="step === 'PAYMENT_METHOD'" />
          <app-summary-confirm v-if="step === 'CONFIRM_SUMMARY'" />
          <PaypalPayment v-if="step === 'PAYPAL_PAYMENT' && conventionalPayments.length" />
          <app-order-success v-if="step === 'CONFIRM_SUCCESS'" />
          <app-checkout-payment v-if="step === 'CHECKOUT_PAYMENT'" />
        </div>
        <div
          v-if="
            step != 'CURRENT_PRODUCTS' && step != 'CONFIRM_SUCCESS' && step != 'CHECKOUT_PAYMENT'
          "
          class="back-container"
        >
          <div class="pointer" @click="lastStep">
            <span class="icon">
              <img src="@/assets/icon-arrow-left.svg" />
              Volver
            </span>
          </div>
        </div>
        <div
          v-if="
            shop &&
            shop.contactInfo.phone &&
            (step == 'CONFIRM_SUCCESS' || step == 'CHECKOUT_PAYMENT')
          "
          class="contact-container"
        >
          <div class="text">Si tienes consultas puedes comunicarte al teléfono</div>
          <span class="icon">
            <img src="@/assets/icon-phone.svg" />
          </span>
          <div class="number">
            {{ shop.contactInfo.phone }}
          </div>
        </div>
      </div>
      <div class="column column-invoice">
        <div class="level header-cart" @click="showDetail = !showDetail">
          <div class="level-item">
            <span class="icon">
              <img src="@/assets/icon-shopping-car.svg" />
            </span>
            <h2 class="subtitle-invoice">RESUMEN DEL PEDIDO</h2>
          </div>
          <div class="level-right mobile">
            <span class="button-show-detail">{{ showDetail ? 'ocultar' : 'ver' }}</span>
          </div>
        </div>
        <app-invoice
          :step="step"
          :loading-next="loadingNext"
          :showDetail="showDetail"
          @nextStep="nextStep"
          @go-to-start="goToStart"
          @changeShowDetail="changeShowDetail"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { get } from 'vuex-pathify'
import store from '@/store'

import AppInvoice from '@/components/shopping-cart/Invoice'
import AppCheckoutPayment from '@/components/shopping-cart/CheckoutPayment'
import AppSummaryConfirm from '@/components/shopping-cart/SummaryConfirm'
import AppOrderSuccess from '@/components/shopping-cart/OrderSuccess'
import AppOrderPayment from '@/components/shopping-cart/OrderPayment'
import AppOrderShipping from '@/components/shopping-cart/OrderShipping'
import AppProductsInCart from '@/components/shopping-cart/ProductsInCart'
import BannerHasNoIvaDays from '@/components/layout/BannerHasNoIvaDays.vue'
import NoStockBanner from '@/components/shopping-cart/NoStockBanner'
import PaypalPayment from '@/components/shopping-cart/payment/PaypalPayment'

import AppLoading from '@/components/shared/Loading'

import EventBus from '@/eventBus'

import { ALLOWED_PAYMENT_METHODS } from '@/utils/constants'

const { PAYPAL } = ALLOWED_PAYMENT_METHODS

export default {
  name: 'ShoppingCart',
  components: {
    AppInvoice,
    AppOrderShipping,
    AppProductsInCart,
    AppSummaryConfirm,
    AppOrderSuccess,
    AppOrderPayment,
    AppLoading,
    AppCheckoutPayment,
    BannerHasNoIvaDays,
    NoStockBanner,
    PaypalPayment
  },
  metaInfo() {
    const title = 'Tienda - Carrito de compras'
    const description = 'Carrito de compras'
    // const title = `Carrito de compras ${this.shop ? 'de ' + this.shop.name : ''}`
    return {
      title,
      meta: [
        { name: 'title', content: title },
        { property: 'og:title', content: title },
        { name: 'description', content: description },
        { property: 'og:description', content: description },
        { property: 'og:site_name', content: title },
        { property: 'og:type', content: 'cart' },
        { property: 'og:url', content: `https://${this.shop.domain}${this.$route.path}` },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  },
  data() {
    return {
      step: 'CURRENT_PRODUCTS',
      loadingNext: false,
      showDetail: true,
      loadingCheckout: false
    }
  },
  computed: {
    applicationVersion: get('shop@shop.config.applicationVersion'),
    ...mapState('shipping', ['loadingShipping']),
    ...mapState('cart', ['productsInCart', 'address', 'showNoStockWarning']),
    ...mapState('shop', ['shop']),
    ...mapState('order', ['lastOrder']),
    ...mapGetters('config', ['mode']),
    ...mapGetters('shipping', ['activeCityShipping']),
    ...mapGetters('shop', ['conventionalPayments']),
    queryParams() {
      const query = {}
      if (this.mode) query.mode = this.mode
      return query
    }
  },
  async created() {
    await this.getDepartments()
    await this.getIdentificationTypes()

    store.set('order/openedExternalPayment', false)

    const idOrder = this.$route.params.idOrder
    if (idOrder) {
      this.checkoutPayment(idOrder)
    }
  },
  mounted() {
    try {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    } catch (e) {}

    EventBus.$on('NEXT_STEP_CART', ({ step }) => {
      this.goStep(step)
    })

    EventBus.$emit('CLEAN_FORM_CLIENT')
  },
  methods: {
    ...mapActions('order', ['newOrder', 'getOrder', 'getPayment']),
    ...mapActions('shipping', ['getShippingMethods']),
    ...mapActions('config', ['getDepartments', 'getCities', 'getIdentificationTypes']),
    nextStep({ step }) {
      const paymentMethod = store.get('cart/paymentMethod')

      if (this.step === 'DATA_CONTACT') {
        EventBus.$emit('VALIDATE_DATA_SHIPPING')
        this.getShippingMethods({ city: this.activeCityShipping ? this.address?.city : '' })
      } else if (this.step === 'CONFIRM_SUMMARY' && paymentMethod.provider === PAYPAL) {
        this.step = 'PAYPAL_PAYMENT'
      } else if (this.step === 'CONFIRM_SUMMARY') {
        this.createOrder()
      } else {
        this.goStep(step)
      }
    },
    goToStart() {
      this.step = 'CURRENT_PRODUCTS'
    },
    lastStep(step) {
      if (this.step === 'DATA_CONTACT') {
        this.goStep('CURRENT_PRODUCTS')
      } else if (this.step === 'PAYMENT_METHOD') {
        this.goStep('DATA_CONTACT')
      } else if (this.step === 'CONFIRM_SUMMARY') {
        this.goStep('PAYMENT_METHOD')
      } else if (this.step === 'PAYPAL_PAYMENT') {
        this.goStep('CONFIRM_SUMMARY')
      }
    },
    goStep(step) {
      if (step === 'CONFIRM_SUMMARY' && this.loadingShipping) {
        return false
      }
      this.step = step
    },
    createOrder() {
      this.loadingNext = true
      this.newOrder()
        .then(order => {
          this.loadingNext = false
          if (order && order.id) {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              event: 'eventApp',
              eventAppCategory: 'Shop',
              eventAppAction: 'New Order',
              eventAppLabel: `${this.shop.id}`
            })

            this.goStep('CONFIRM_SUCCESS')
            try {
              window.scrollTo({ top: 0, behavior: 'smooth' })
            } catch (e) {}
            if (
              this.lastOrder &&
              this.lastOrder.payments &&
              this.lastOrder.payments[0].status === 'FAILED'
            ) {
              window.open(
                `/checkout/orders/${this.lastOrder.id}/payment/${this.lastOrder.payments[0].id}`,
                '_self'
              )
            }
            if (
              this.lastOrder &&
              (this.lastOrder.paymentMethod.provider === 'PAYU' ||
                this.lastOrder.paymentMethod.provider === 'VISANET')
            ) {
              setTimeout(() => {
                if (this.lastOrder.payments && this.lastOrder.payments.length > 0) {
                  const paymentInfo = this.lastOrder.payments[0].paymentInfo
                  var formPayu = document.createElement('form')
                  formPayu.setAttribute('method', 'post')
                  formPayu.setAttribute('action', paymentInfo.url)

                  for (var attr in paymentInfo.payload) {
                    var field = document.createElement('input')
                    field.setAttribute('name', attr)
                    field.setAttribute('value', paymentInfo.payload[attr])
                    field.setAttribute('type', 'hidden')
                    formPayu.appendChild(field)
                  }
                  document.body.appendChild(formPayu)
                  formPayu.submit()
                }
              }, 4000)
            }
            if (['MERCADO_PAGO', 'GREENPAY'].includes(this.lastOrder?.paymentMethod?.provider)) {
              this.checkPymentTimeOut()
            }
          }
        })
        .catch(e => {
          this.loadingNext = false
        })
    },
    checkPymentTimeOut() {
      setTimeout(() => {
        if (this.lastOrder.payments && this.lastOrder.payments.length > 0) {
          window.open(this.lastOrder.payments[0].paymentInfo.url, '_blank')
          store.set('order/openedExternalPayment', true)
          setTimeout(() => {
            setInterval(() => {
              this.getPayment({ idPayment: this.lastOrder.payments[0].id }).then(payment => {
                if (payment && payment.status !== 'PENDING') {
                  window.open(
                    `/checkout/orders/${this.lastOrder.id}/payment/${payment.id}`,
                    '_self'
                  )
                }
              })
            }, 10000)
          }, 60000)
        }
      }, 4000)
    },
    changeShowDetail(value) {
      this.showDetail = value
    },
    checkoutPayment(idOrder) {
      this.loadingCheckout = true
      if (this.shop) {
        this.getOrder({ idOrder, extraAttrs: 'paymentMethod,items,payments,paymentsValidated' })
          .then(order => {
            if (order && order.id && order.payments && order.payments.length > 0) {
              store.set('order/lastOrder', order)
              store.dispatch('cart/cleanCart')
              this.goStep('CHECKOUT_PAYMENT')
              try {
                window.scrollTo({ top: 0, behavior: 'smooth' })
              } catch (e) {}
            } else {
              this.$router.push({ name: 'home', query: this.queryParams })
            }
            this.loadingCheckout = false
          })
          .catch(e => {
            this.loadingCheckout = false
          })
      } else {
        setTimeout(() => {
          this.checkoutPayment(idOrder)
        }, 2000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.loader-wrapper {
  margin-top: 80px;
}
.container-layout {
  background-color: #fff !important;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
}
.header-cart {
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  height: 52px;
  margin: 0;
  padding-left: 29px;
}
.header-cart .level-item {
  justify-content: flex-start;
}
.column-main {
  position: relative;
  padding-bottom: 60px !important;
}
.main-cart {
  padding: 13px 17px;
}
.back-container {
  display: flex;
  align-items: center;
  border-top: 1px solid #ededed;
  position: absolute;
  bottom: 0;
  height: 56px;
  width: 90%;
  margin-left: 5%;
  span {
    font-size: 16px;
    line-height: 24px;
    color: #47536b;
    margin-left: 15px;
  }
}
.contact-container {
  display: flex;
  align-items: center;
  border-top: 1px solid #ededed;
  position: absolute;
  bottom: 0;
  height: 56px;
  width: 100%;
  padding-left: 5%;
  .text {
    font-size: 12px;
    line-height: 24px;
    color: #47536b;
  }
  .icon {
    font-size: 16px;
    line-height: 24px;
    color: #47536b;
    margin-left: 15px;
    img {
      margin-right: 0;
    }
  }
  .number {
    font-size: 16px;
    line-height: 24px;
    color: #29303d;
    background-color: #fff;
  }
}
.step-number {
  width: 25px;
  height: 25px;
  font-size: 11px;
  padding-top: 3px;
  box-sizing: border-box;
  border-radius: 20.5264px;
}
.step-active {
  color: #29303d;
}
.step-inactive {
  color: #a3adc2;
}
.subtitle-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 18px;
}
.subtitle-cart .icon {
  margin-left: 15px;
}
.subtitle-invoice {
  font-size: 14px;
  line-height: 21px;
  color: #29303d;
}
.columns {
  margin: 0;
}
.column {
  margin: 0;
  padding: 0;
}
.column-invoice {
  background-color: #f4f5fb;
  border-left: 1px solid #ededed;
}
.icon img {
  width: 18px;
  height: 18px;
  margin-right: 14px;
}

@media (max-width: 768px) {
  .header-cart {
    justify-content: space-around;
    padding-left: 5px;
    .level-item {
      margin-bottom: 0;
    }
  }
  .step-active {
    .subtitle-cart {
      .text-short {
        display: none;
      }
    }
  }
  .step-inactive {
    .subtitle-cart {
      .text-long {
        display: none;
      }
      .text-short {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ebedf1;
        color: #7484a5;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        margin-right: 5px;
      }
    }
  }
  .button-show-detail {
    padding: 4px 8px;
    border: #a3adc2 solid 0.5px;
    color: #7484a5;
  }
  .contact-container {
    .icon {
      margin-left: 0;
      margin-right: 10px;
    }
  }
}
</style>
