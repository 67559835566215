<template>
  <div
    class="level payment-option"
    :class="{'payment-option--selected' : selected }"
    @click="$emit('select-payment', option.id)"
  >
    <div class="level-item icon">
      <img
        v-if="!option.provider"
        :src="option.logoFiles ? option.logoFiles.url : require('@/assets/icon-payment-generic.svg')"/>
      <img
        :class="option.provider.toLowerCase()"
        v-else
        :src="require(`@/assets/logo-${option.provider.toLowerCase()}.png`)"
      />
    </div>
    <div class="level-item name">
      <h3>{{ option.name }}</h3>
    </div>
    <div class="level-item arrow">
      <img src="@/assets/icon-arrow-right.svg">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option: { type: Object, required: true },
    selected: { type: Boolean, required: false }
  }
}
</script>

<style lang="scss" scoped>
  .payment-option {
    cursor: pointer;
    background: var(--color-light);
    border: 1px solid var(--color-border-box);
    box-sizing: border-box;
    border-radius: 5px;
    height: 60px;
    &--selected {
      border: 1px solid var(--color-border-box-selected);
    }
    h3 {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
      color: var(--color-dark);
      mix-blend-mode: normal;
    }
    .icon {
      margin-left: 10px;
      flex: 0.1;
      justify-content: center;
      img {
        width: 70%;
      }
      img.visanet {
        width: 100%;
      }
      img.greenpay {
        width: 75%;
      }
    }
    .name {
      flex: 0.7;
      justify-content: flex-start;
    }
    .arrow {
      flex: 0.1;
      justify-content: center;
    }
  }
@media (max-width: 768px) {
  .level-item {
    margin-bottom: 0;
  }
}
</style>
