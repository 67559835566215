<template>
  <div class="variant-product">
    <div v-for="(variant, indexVariant) in variants" :key="indexVariant">
      <h3 class="label-detail">{{ variant.name }}</h3>
      <app-selector
        :disabledIsSelectable="true"
        :options="variant.options.map(({ id, value, disabled }) => ({ value: id, label: value, disabled }))"
        :value="variantsSelected[indexVariant]?.idOption || ''"
        @change="optionId => changeSelect({ optionId, variantIndex: indexVariant, variantId: variant.id })"
      />
    </div>
    <div class="error-message">
      <span v-if="errorMessage">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script>
import AppSelector from '@/components/shared/Selector.vue'

export default {
  components: { AppSelector },
  props: {
    product: { type: Object, required: true },
    errorMessage: { type: String, required: false, default: '' }
  },
  data: function() {
    return {
      variantsSelected: []
    }
  },
  computed: {
    variants() {
      const variants = [...this.product.variantAttributes]

      if (variants.length === 1) {
        const variant = variants[0]

        variant.options.forEach(variantOption => {
          variantOption.disabled = true

          this.product.itemVariants.forEach(itemVariant => {
            const variantAttribute = itemVariant.variantAttributes[0]

            variantAttribute.options.forEach(({ id }) => {
              if (id !== variantOption.id) return

              const inventory = itemVariant.inventorySettings
              if (
                inventory?.sellWithoutStock ||
                (!inventory?.sellWithoutStock && inventory?.quantity)
              ) variantOption.disabled = false
            })
          })
        })
      } else {
        if (!this.variantsSelected?.length) {
          this.$emit('updateVariantsSelected', this.variantsSelected)
          return variants
        }

        this.variantsSelected.forEach(selectedVariant => {
          if (!selectedVariant) return

          variants.forEach(variant => {
            if (variant.id === selectedVariant.idVariant) return

            variant.options.forEach(variantOption => {
              variantOption.disabled = true

              this.product.itemVariants.forEach(itemVariant => {
                const selectedVariantExists = itemVariant.variantAttributes.find(({ id }) => id === selectedVariant.idVariant)
                const selectedOptionExists = selectedVariantExists?.options.find(({ id }) => id === selectedVariant.idOption)
                if (!selectedOptionExists) return

                itemVariant.variantAttributes.forEach(variantAttribute => {
                  if (variantAttribute.id === selectedVariant.idVariant) return

                  variantAttribute.options.forEach(({ id }) => {
                    if (id !== variantOption.id) return

                    const inventory = itemVariant.inventorySettings
                    if (
                      inventory?.sellWithoutStock ||
                      (!inventory?.sellWithoutStock && inventory?.quantity)
                    ) variantOption.disabled = false
                  })
                })
              })
            })
          })
        })
      }

      this.$emit('updateVariantsSelected', this.variantsSelected)
      return variants
    }
  },
  methods: {
    changeSelect({ optionId, variantIndex, variantId }) {
      if (!optionId) {
        // Limpia la variante seleccionada
        this.variantsSelected = Object.assign([...this.variantsSelected], {
          [variantIndex]: null
        })

        // Desbloquea las variantes sin selección
        const updatedVariants = [...this.variants]
        for (const variant of updatedVariants) {
          for (const option of variant.options) {
            const selectedVariant = this.variantsSelected.find(variant => variant?.idOption === option.id)
            if (!selectedVariant) option.disabled = false
          }
        }
        this.product.variantAttributes = updatedVariants
      } else {
        this.variantsSelected = Object.assign([...this.variantsSelected], {
          [variantIndex]: {
            idVariant: variantId,
            idOption: optionId
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.variant-product {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.label-detail {
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}
.error-message {
  display: flex;
  align-items: center;
  min-height: 30px;
  color: #e63f3f;
  font-size: 13px;
}
</style>
