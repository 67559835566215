<template>
  <div id="app" :style="colorsVars">
    <app-loading
      v-if="loading"
      :loading="true"
      :size="isMobile ? 'medium' : 'large'"
      :style="{ marginTop: '200px' }"
    />
    <page-shop-not-available v-else-if="shop && shop.status == 'inactive' && mode !== 'preview'" />
    <div v-else class="app-main">
      <app-header
        v-if="
          $router.currentRoute.name != 'terms' && $router.currentRoute.name != 'shop-not-available'
        "
        class="desktop"
      />
      <app-header-mobile class="mobile" v-if="$router.currentRoute.name != 'shop-not-available'" />
      <div v-if="showMenuMobile" class="background-menu mobile" @click="closeMenu()" />
      <transition name="menu-fade">
        <div
          class="column-aside"
          v-if="
            (!isMobile &&
              $router.currentRoute.name != 'terms' &&
              $router.currentRoute.name != 'shop-not-available') ||
            (isMobile && showMenuMobile)
          "
        >
          <aside class="aside-categories">
            <app-categories />
          </aside>
        </div>
      </transition>
      <div class="column-main">
        <main class="main" v-if="shop && (shop.status == 'active' || mode === 'preview')">
          <router-view />
        </main>
        <div class="shop-inactive" v-if="shop && shop.status == 'inactive' && mode !== 'preview'">
          <img src="@/assets/icon-settings.svg" />
          <h2>{{ shop.name }} no está disponible.</h2>
        </div>
        <app-footer class="app-footer" v-if="$router.currentRoute.name != 'shop-not-available'" />
      </div>
    </div>
    <app-info-modal
      v-if="infoModalIsOpen"
      :title="infoModalData.title"
      :description="infoModalData.description"
      @toggle-modal="closeInfoModal"
    />
    <app-product-added-success />
    <app-add-product-whith-variants />
    <app-notification />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { get } from 'vuex-pathify'
import store from '@/store'

import AppHeaderMobile from '@/components/layout/HeaderMobile'
import AppHeader from '@/components/layout/Header'
import AppFooter from '@/components/layout/Footer'
import AppCategories from '@/components/layout/Categories'
import AppNotification from '@/components/shared/Notification'

import AppProductAddedSuccess from '@/components/products/ProductAddedSuccess'
import AppAddProductWhithVariants from '@/components/products/AddProductWhithVariants'

import PageShopNotAvailable from '@/pages/ShopNotAvailable'
import AppLoading from '@/components/shared/Loading'
import AppInfoModal from '@/components/shared/InfoModal.vue'

import * as Sentry from '@sentry/vue'
import urlUtil from './utils/url'

export default {
  name: 'app',
  computed: {
    ...mapState('shop', ['shop', 'loading']),
    ...mapGetters('config', ['mode']),
    ...mapGetters('app', ['isMobile']),
    showMenuMobile: get('app/showMenuMobile'),
    shopTemplate: get('shop/shop@template'),
    infoModalIsOpen: get('modal@infoModal.isOpen'),
    infoModalData: get('modal@infoModal.data'),
    colorsVars() {
      const paletteValid = this.shopTemplate?.colorScheme && this.shopTemplate.colorScheme.id !== 1
      return {
        '--color-border-box': '#e4e9f6',
        '--color-border-box-selected': '#00b19d',
        '--color-disabled': '#bcbcbc',
        '--color-light': '#ffffff',
        '--color-dark': '#334155',
        '--color-info-bg': '#e0e7ff',
        '--color-info-border': '#4f46e540',
        '--color-primary': paletteValid ? this.shopTemplate.colorScheme.primary : '#fff',
        '--color-secondary': paletteValid ? this.shopTemplate.colorScheme.secondary : '#fff',
        '--color-tertiary': paletteValid ? this.shopTemplate.colorScheme.tertiary : '#47536b',

        '--color-text': paletteValid ? '#fff' : '#424754',
        '--color-border': paletteValid ? this.shopTemplate.colorScheme.primary : '#e4e9f6',
        '--color-cart': paletteValid ? this.shopTemplate.colorScheme.tertiary : '#fff',
        '--color-search-container': paletteValid
          ? this.shopTemplate.colorScheme.secondary
          : '#f4f5fb',
        '--color-icon': paletteValid ? '#fff' : '#abb3c3',
        '--color-menu': paletteValid ? '#fff' : '#47536B',
        '--color-contrast': paletteValid ? this.shopTemplate.colorScheme.primary : '#47536B'
      }
    },
    queryParams() {
      const query = {}
      if (this.mode) query.mode = this.mode
      return query
    }
  },
  components: {
    AppHeader,
    AppFooter,
    AppCategories,
    AppHeaderMobile,
    AppProductAddedSuccess,
    AppAddProductWhithVariants,
    AppNotification,
    PageShopNotAvailable,
    AppLoading,
    AppInfoModal
  },
  created() {
    store.set('app/windowWidth', window.innerWidth)
    // document.title = 'Alegra Tienda'

    let subdomain = window.location.hostname
    if (process.env.VUE_APP_ENVIROMENT === 'local') {
      // Catalogo COL
      // subdomain = 'jdcatalogo1'

      // INT
      subdomain = 'naftanshop-dev'

      // ARG
      // subdomain = 'compus-1'

      // MEX
      // subdomain = 'jd-test-mex-3'

      // RD
      // subdomain = 'test-dominicana'

      // FE CRI
      // subdomain = 'sdasadasd'

      // PERU
      // subdomain = 'jd-peru-test'

      // FE COL?
      // subdomain = 'decordecorprueba2'

      // ARG
      // subdomain = 'jd-argentina-test'

      subdomain = subdomain + '.almost-alegrashop.com'
      // subdomain = 'antonella.alegratienda.com'
    }

    if (subdomain) {
      this.getShopBySubdomain({ subdomain }).then(shop => {
        const mode = urlUtil.getQueryParams('mode')
        if (mode) {
          this.setMode(mode)
        } else {
          this.setMode(null)
        }

        if (shop && (shop.status === 'active' || this.mode === 'preview')) {
          if (process.env.VUE_APP_ENVIROMENT !== 'local') {
            Sentry.setUser({
              id: shop.id,
              username: subdomain
            })
          }
          this.getCategories()
          if (this.$route.name === 'category') {
            const category = this.$route.params.category.split('-')
            if (category.length > 1) {
              store.set('categories/categorieSelected', { id: category[category.length - 1] })
            } else {
              this.$router.replace({ name: '404', query: this.queryParams })
            }
          }

          store.set('products/searchPage', 0)

          this.getActiveCampaigns()
          this.getPaymentMethods()
          this.getProducts()
        }
      })
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      store.set('app/windowWidth', window.innerWidth)
    })
  },
  methods: {
    ...mapActions('shop', ['getShopBySubdomain', 'getPaymentMethods']),
    ...mapActions('categories', ['getCategories']),
    ...mapActions('products', ['getProducts']),
    ...mapActions('campaigns', ['getActiveCampaigns']),
    closeMenu() {
      store.set('app/showMenuMobile', false)
    },
    closeInfoModal() {
      store.set('modal/infoModal@isOpen', false)
    },
    setMode(value) {
      store.set('config/mode', value)
    }
  }
}
</script>

<style lang="scss">
@import './main.scss';

html {
  background-color: #f4f5fb;
  position: relative;
  min-height: 100vh;
}

.app-main {
  display: flex;
  min-height: 100vh;
  height: 100%;
  width: 100%;
}
.column-aside {
  position: fixed;
  top: 76px;
  width: 100%;
  z-index: 9;
}
.column-main {
  margin-top: 114px;
  position: relative;
  width: -moz-available;
  width: -webkit-fill-available;
}
.aside-categories {
  max-width: 980px;
  margin: 0 auto;
  height: 100%;
  background: var(--color-secondary);
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
}
.container-main {
  width: 80%;
}
.main {
  max-width: 1011px;
  padding: 0 15px;
  margin: 0 auto;
}
.app-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.link {
  cursor: pointer;
}
.shop-inactive {
  display: flex;
  flex-flow: column;
  align-items: center;
  h2 {
    font-size: 20px;
    color: #47536b;
  }
}
@media (max-width: 768px) {
  .desktop {
    display: none !important;
  }
  .app-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  // .main {
  //   margin: 0px 20px;
  // }
  .column-aside {
    top: 0;
    margin: 0;
    width: 70%;
    height: 100%;
  }
  .column-main {
    margin-top: 126px;
  }
  .background-menu {
    background-color: rgba(0, 0, 0, 0.24);
    width: 100%;
    position: fixed;
    height: 100%;
    z-index: 8;
  }
  /* Las animaciones del menu */
  .menu-fade-enter-active {
    transition: all 0.5s ease;
  }
  .menu-fade-leave-active {
    transition: all 0.6s ease;
  }
  .menu-fade-enter,
  .menu-fade-leave-to {
    transform: translateX(-100%);
    opacity: 0.7;
  }
  /* Fin de Las animaciones del menu */
}
@media (min-width: 769px) {
  .mobile {
    display: none !important;
  }
}
</style>
