/* Validations for Vuelidate */
import {
  IDENTIFICATION_PERU_DNI_FORMAT_LENGTH,
  IDENTIFICATION_DOM_RNC_FORMAT_LENGTH,
  IDENTIFICATION_DOM_CED_FORMAT_LENGTH
} from './constants'

export const isValidDNIPeru = (value, context) => {
  const identificationTypeValue = context.identificationType?.key

  if (identificationTypeValue !== 'DNI') return true

  if (value?.length !== IDENTIFICATION_PERU_DNI_FORMAT_LENGTH) return false

  return true
}

export const isValidRncDom = (value, context) => {
  const identificationTypeValue = context.identificationType?.key

  if (identificationTypeValue !== 'RNC') return true

  if (value?.length !== IDENTIFICATION_DOM_RNC_FORMAT_LENGTH) return false

  return true
}

export const isValidCedDom = (value, context) => {
  const identificationTypeValue = context.identificationType?.key

  if (identificationTypeValue !== 'CED') return true

  if (value?.length !== IDENTIFICATION_DOM_CED_FORMAT_LENGTH) return false

  return true
}
