<template>
  <div class="selector">
    <div class="selector__input-container">
      <input
        class="selector__input"
        type="text"
        v-model="searchText"
        :placeholder="placeholder || 'Seleccionar'"
        @focusin="isOpenOptions = true"
      />
      <button
        v-if="searchText"
        class="selector__clean-btn"
        @click="cleanSelection()"
      >
        <figure>
          <img src="@/assets/icon-close.svg" alt="Clean" />
        </figure>
      </button>
    </div>
    <ul v-if="isOpenOptions" class="selector__options-container">
      <li
        v-for="option in filteredOptions" :key="option.value"
        class="selector__option"
        :class="{
          'selector__option--selected' : option.value === value,
          'selector__option--disabled' : option.disabled,
          'selector__option--selectable-disabled' : disabledIsSelectable && option.disabled,
          'selector__option--selected-disabled' : disabledIsSelectable && option.value === value && option.disabled
        }"
        @click="selectOption(option)"
      >
        <span
          class="selector__option-text"
          :class="{
            'selector__option-text--selected' : option.value === value,
            'selector__option-text--disabled' : option.disabled,
            'selector__option-text--selected-disabled' : disabledIsSelectable && option.value === value && option.disabled
          }"
        >
          {{ option.label }}
        </span>
      </li>
      <div v-if="!filteredOptions.length" class="selector__options-container-info">
        <p class="selector__options-container-info-text">No hay opciones relacionadas</p>
      </div>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'app-selector',
  props: {
    placeholder: { type: String, required: false },
    options: { type: Array, required: true },
    value: { type: String, required: false },
    disabledIsSelectable: { type: Boolean, required: false }
  },
  data() {
    return {
      searchText: '',
      isOpenOptions: false,
      filteredOptions: []
    }
  },
  watch: {
    options() {
      this.filterOptions()
    },
    searchText(label) {
      this.filterOptions()
      const option = this.options.find(option => option.label === label)
      if (option) {
        this.$emit('change', option.value)
      } else {
        this.$emit('change', '')
      }
    }
  },
  methods: {
    filterOptions() {
      this.filteredOptions = this.options.filter(({ label }) => label.toLowerCase().includes(this.searchText.toLowerCase()))
    },
    selectOption({ value, disabled }) {
      if (disabled && !this.disabledIsSelectable) return
      this.searchText = this.options.find(option => option.value === value).label
      this.$emit('change', value)
      this.isOpenOptions = false
    },
    cleanSelection() {
      this.searchText = ''
      this.$emit('change', '')
      this.isOpenOptions = false
    },
    closeOptionsOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpenOptions = false
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.closeOptionsOutside)

    if (this.value) this.searchText = this.options.find(option => option.value === this.value)?.label || ''
    this.filteredOptions = this.options
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeOptionsOutside)
  }
}
</script>

<style scoped lang='scss'>
.selector {
  position: relative;

  &__input-container {
    border: 1px solid var(--color-border);
    border-radius: 4px;
    padding: 5px;
    display: flex;
    gap: 5px;
    align-items: center;
    background-color: var(--color-light);
  }

  &__input {
    width: 100%;
    border: none;
    font-size: 12px;
    background-color: transparent;
    outline: none;
  }

  &__clean-btn {
    border: 0;
    padding: 0;
    background-color: transparent;

    figure {
      display: flex;

      img {
        width: 10px;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__options-container {
    width: 100%;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 32px;
    background-color: var(--color-light);
    box-shadow: 0 2px 5px 0 rgba($color: var(--color-primary), $alpha: 0.25);
    overflow: hidden;
    z-index: 5;
  }

  &__options-container-info {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
  }

  &__options-container-info-text {
    font-size: 12px;
    color: var(--color-disabled);
  }

  &__option {
    padding: 1px 10px;

    &:hover {
      cursor: pointer;
      background-color: var(--color-tertiary);

      .selector__option-text {
        color: var(--color-light);
      }
    }

    &--selected {
      background-color: var(--color-tertiary);
    }
    &--disabled {
      &:hover {
        cursor: default;
        background-color: transparent;

        .selector__option-text {
          color: var(--color-disabled);
        }
      }
    }
    &--selectable-disabled {
      &:hover {
        cursor: pointer;
        background-color: var(--color-disabled);

        .selector__option-text {
          color: var(--color-dark);
        }
      }
    }
    &--selected-disabled {
      background-color: var(--color-disabled);
    }
  }

  &__option-text {
    font-size: 12px;

    &--selected {
      color: var(--color-light);
    }
    &--disabled {
      color: var(--color-disabled);
    }
    &--selected-disabled {
      color: var(--color-dark);
    }
  }
}
</style>
