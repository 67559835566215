<template>
  <section>
    <div v-if="productsInCart && productsInCart.length == 0" class="no-products">
      <img src="@/assets/icon-shopping-car.svg" />
      <h2>Tu carrito está vacío</h2>
      <p>En {{ shop.name }} tenemos los mejores productos para ti</p>
      <router-link
        class="button"
        :to="{
          name: 'home',
          query: queryParams
        }"
      >
        VER PRODUCTOS
      </router-link>
    </div>

    <div v-if="loadingCartProducts" class="product-cart-loading">
      <Loading loading />
    </div>
    <template v-else>
      <div v-for="(product, index) in productsInCart" :key="index">
        <CartProduct :product="product" />
      </div>
    </template>
  </section>
</template>

<script>
import Loading from '@/components/shared/Loading'
import CartProduct from './CartProduct'
import { mapGetters, mapState, mapActions } from 'vuex'
import urlUtil from '@/utils/url'

export default {
  components: {
    Loading,
    CartProduct
  },
  computed: {
    ...mapState('cart', ['productsInCart', 'loadingCartProducts']),
    ...mapState('shop', ['shop']),
    ...mapState('products', ['productsInCartInventory']),
    ...mapGetters('config', ['mode']),
    queryParams() {
      const query = {}
      if (this.mode) query.mode = this.mode
      return query
    }
  },
  methods: {
    ...mapActions('cart', ['updateQuantityProductSelected', 'removeProduct']),
    setQuantity(quantity, index) {
      this.updateQuantityProductSelected({ quantity, index })
    },
    remove(index) {
      this.removeProduct({ index })
    },
    formatUrl(val) {
      return urlUtil.formatSeoUrl(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.product-cart-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.no-products {
  text-align: center;
  margin-top: 20px;
  img {
    height: 50px;
  }
  h2 {
    font-size: 30px;
    color: #8592ad;
    font-weight: 500;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 10px;
    color: #8592ad;
  }
  .button {
    background: var(--color-tertiary);
    border: 1px solid var(--color-tertiary);
    box-sizing: border-box;
    color: #fff;
    font-size: 14px;
    border-radius: 0;
    line-height: 24px;
    text-transform: uppercase;
  }
}
</style>
