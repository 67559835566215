import { make } from 'vuex-pathify'
import actions from './actions'
import gettersCustom from './getters'

const inistialState = () => ({
  mode: null,
  cities: null,
  departments: null,
  loadingCities: false,
  identificationTypes: null,
  loadingIdentificationTypes: false
})

const state = inistialState()

const getters = {
  ...gettersCustom,
  ...make.getters(state)
}

const mutations = make.mutations(state)

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
