<template>
  <div
    class="shipping-option"
    :class="{'shipping-option--selected' : selected }"
    @click="$emit('select-shipping', id)"
  >
    <div class="shipping-option__section">
      <strong class="shipping-option__name">{{ name }}</strong>
      <p v-if="description" class="shipping-option__description">{{ description }}</p>
    </div>
    <div class="shipping-option__section">
      <strong class="shipping-option__price">{{ $currencyFormatter(price) }}</strong>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-shipping-option',
  props: {
    id: { type: Number, required: true },
    name: { type: String, required: true },
    description: { type: String, required: false },
    price: { type: Number, required: true },
    selected: { type: Boolean, required: false }
  }
}
</script>

<style lang="scss" scoped>
.shipping-option {
  padding: 10px 15px;
  border: 1px solid var(--color-border-box);
  border-radius: 5px;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
  background: var(--color-light);

  &--selected {
    border: 1px solid var(--color-border-box-selected);
  }

  &:hover {
    cursor: pointer;
  }

  &__name {
    font-size: 12px;
    text-transform: uppercase;
    color: var(--color-dark);
  }

  &__description {
    font-size: 11px;
    color: var(--color-dark);
    opacity: 0.5;
  }

  &__price {
    font-size: 12px;
    color: var(--color-dark);
  }
}
</style>
