var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "level payment-option",
    class: {
      'payment-option--selected': _vm.selected
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('select-payment', _vm.option.id);
      }
    }
  }, [_c('div', {
    staticClass: "level-item icon"
  }, [!_vm.option.provider ? _c('img', {
    attrs: {
      "src": _vm.option.logoFiles ? _vm.option.logoFiles.url : require('@/assets/icon-payment-generic.svg')
    }
  }) : _c('img', {
    class: _vm.option.provider.toLowerCase(),
    attrs: {
      "src": require(("@/assets/logo-" + (_vm.option.provider.toLowerCase()) + ".png"))
    }
  })]), _c('div', {
    staticClass: "level-item name"
  }, [_c('h3', [_vm._v(_vm._s(_vm.option.name))])]), _vm._m(0)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "level-item arrow"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon-arrow-right.svg")
    }
  })]);
}]

export { render, staticRenderFns }