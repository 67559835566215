var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('h1', [_vm._v("404")]), _c('h2', [_vm._v("Página no encontrada")]), _c('p', [_vm._v("Te invitamos a ver nuestros productos")]), _c('router-link', {
    staticClass: "button",
    attrs: {
      "to": {
        name: 'home',
        query: _vm.queryParams
      }
    }
  }, [_vm._v(" VER PRODUCTOS ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }