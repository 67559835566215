import storeService from '@/services/store'
import store from '@/store'

const actions = {
  getShopByIdCompany(context, payload) {
    store.set('shop/loading', true)
    return storeService
      .getByIdCompany(payload.idCompany)
      .then(res => {
        store.set('shop/shop', res.stores[0] || {})
        store.set('shop/loading', false)
        return res.stores[0]
      })
      .catch(e => {
        store.set('shop/loading', false)
      })
  },
  getShopBySubdomain(context, payload) {
    store.set('shop/loading', true)
    return storeService
      .getShopBySubdomain(payload.subdomain)
      .then(res => {
        store.set('shop/shop', res.store || {})
        store.set('shop/loading', false)
        return res.store
      })
      .catch(e => {
        store.set('shop/loading', false)
      })
  },
  getPaymentMethods({ dispatch }) {
    const idStore = store.get('shop/shop.id')
    return storeService
      .getPaymentMethods(idStore)
      .then(res => {
        if (res?.paymentMethods) {
          store.set('shop/paymentMethods', res.paymentMethods)

          // Autoselecciona el método de pago si es único
          if (res.paymentMethods.length === 1) {
            store.set('cart/paymentMethod', res.paymentMethods[0])
          }

          return res.paymentMethods
        } else {
          setTimeout(() => {
            dispatch('getPaymentMethods')
          }, 5000)
          return false
        }
      })
      .catch(e => e)
  }
}

export default actions
