var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "selector"
  }, [_c('div', {
    staticClass: "selector__input-container"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchText,
      expression: "searchText"
    }],
    staticClass: "selector__input",
    attrs: {
      "type": "text",
      "placeholder": _vm.placeholder || 'Seleccionar'
    },
    domProps: {
      "value": _vm.searchText
    },
    on: {
      "focusin": function ($event) {
        _vm.isOpenOptions = true;
      },
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.searchText = $event.target.value;
      }
    }
  }), _vm.searchText ? _c('button', {
    staticClass: "selector__clean-btn",
    on: {
      "click": function ($event) {
        return _vm.cleanSelection();
      }
    }
  }, [_vm._m(0)]) : _vm._e()]), _vm.isOpenOptions ? _c('ul', {
    staticClass: "selector__options-container"
  }, [_vm._l(_vm.filteredOptions, function (option) {
    return _c('li', {
      key: option.value,
      staticClass: "selector__option",
      class: {
        'selector__option--selected': option.value === _vm.value,
        'selector__option--disabled': option.disabled,
        'selector__option--selectable-disabled': _vm.disabledIsSelectable && option.disabled,
        'selector__option--selected-disabled': _vm.disabledIsSelectable && option.value === _vm.value && option.disabled
      },
      on: {
        "click": function ($event) {
          return _vm.selectOption(option);
        }
      }
    }, [_c('span', {
      staticClass: "selector__option-text",
      class: {
        'selector__option-text--selected': option.value === _vm.value,
        'selector__option-text--disabled': option.disabled,
        'selector__option-text--selected-disabled': _vm.disabledIsSelectable && option.value === _vm.value && option.disabled
      }
    }, [_vm._v(" " + _vm._s(option.label) + " ")])]);
  }), !_vm.filteredOptions.length ? _c('div', {
    staticClass: "selector__options-container-info"
  }, [_c('p', {
    staticClass: "selector__options-container-info-text"
  }, [_vm._v("No hay opciones relacionadas")])]) : _vm._e()], 2) : _vm._e()]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('figure', [_c('img', {
    attrs: {
      "src": require("@/assets/icon-close.svg"),
      "alt": "Clean"
    }
  })]);
}]

export { render, staticRenderFns }