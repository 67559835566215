import Home from '@/pages/Home'
import Product from '@/pages/Product'
import ShoppingCart from '@/pages/ShoppingCart'
import Terms from '@/pages/Terms'
import NotFound from '@/pages/NotFound'
import ShopNotAvailable from '@/pages/ShopNotAvailable'

const routes = [
  { path: '/', component: Home, name: 'home' },
  { path: '/all', name: 'all', redirect: { name: 'home' } },
  { path: '/shopping-cart', component: ShoppingCart, name: 'shopping-cart' },
  { path: '/terms-and-conditions', component: Terms, name: 'terms' },
  { path: '/shop-not-available', component: ShopNotAvailable, name: 'shop-not-available' },

  { path: '/:category', component: Home, name: 'category' },
  { path: '/:category/:product', component: Product, name: 'product' },
  {
    path: '/checkout/orders/:idOrder/payment/:idPayment',
    component: ShoppingCart,
    name: 'checkout-payment'
  },

  { path: '/404', component: NotFound, name: '404' },
  { path: '*', redirect: '404' }
]

export default routes
