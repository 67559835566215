import service from '@/services/shipping'
import store from '@/store'

const actions = {
  getShippingMethods({ dispatch }, payload) {
    const idStore = store.get('shop/shop.id')
    store.set('shipping/loadingShipping', true)
    return service.getShippingMethods(idStore, payload)
      .then(res => {
        if (res?.shippingMethods) {
          store.set('shipping/shippingMethods', res.shippingMethods)

          // Autoselecciona el método de envío si es único
          if (res.shippingMethods.length === 1) {
            store.set('cart/shippingMethod', res.shippingMethods[0])
          }

          return res.shippingMethods
        } else {
          setTimeout(() => {
            dispatch('getShippingMethods')
          }, 5000)
          return false
        }
      })
      .catch(e => e)
      .finally(() => {
        store.set('shipping/loadingShipping', false)
      })
  }
}

export default actions
