import store from '@/store'
import { HOURS_TO_DELETE_CART } from '../../utils/constants'
import { addHoursToCurrentTime } from '../../utils/dates'

function updateProductsInCart(products) {
  store.set('cart/productsInCart', products)
  const currentTime12HoursAhead = addHoursToCurrentTime(HOURS_TO_DELETE_CART)

  try {
    localStorage.setItem('productsInCart', JSON.stringify(products))

    if (products.length) {
      localStorage.setItem('time-to-delete-cart', currentTime12HoursAhead)
    } else {
      localStorage.removeItem('time-to-delete-cart')
    }
  } catch (e) {}
}

const actions = {
  initialise() {
    try {
      if (localStorage.getItem('productsInCart')) {
        store.set('cart/productsInCart', JSON.parse(localStorage.getItem('productsInCart')))
      }

      store.dispatch('cart/shouldClearCart')
    } catch (e) {}
  },
  shouldClearCart() {
    const currentTime = new Date()
    const storedTime = localStorage.getItem('time-to-delete-cart')
    if (!storedTime) return

    const timeToDeleteCart = new Date(storedTime)

    if (currentTime >= timeToDeleteCart) {
      store.dispatch('cart/cleanCart')
    }
  },
  addProductCart(context, { product, quantity }) {
    let isNewProduct = true
    let products = [...store.get('cart/productsInCart')]
    products = products.map(p => {
      if (p.id === product.id) {
        isNewProduct = false
        p.quantity += quantity
      }
      return p
    })
    if (isNewProduct) {
      product.quantity = quantity
      products.push(product)
    }
    updateProductsInCart(products)

    store.set('products/lastAddedProduct@product', product)
    store.set('products/lastAddedProduct@quantity', quantity)
    store.set('modal/addedProductModal@isOpen', true)
  },
  updateQuantityProductSelected(state, { updatedQuantity, productToUpdate }) {
    let products = [...store.get('cart/productsInCart')]

    products = products.map(product => {
      if (product.id === productToUpdate.id) product.quantity = updatedQuantity
      return product
    })

    updateProductsInCart(products)
  },
  async removeProduct(state, productToRemove) {
    let products = [...store.get('cart/productsInCart')]
    products = products.filter(product => product.id !== productToRemove.id)

    if (products.length) {
      store.set('cart/loadingCartProducts', true)
      const productsIDs = products.map(product => product.id).toString()
      await store.dispatch('products/getInventoryAvailability', productsIDs)
      store.set('cart/loadingCartProducts', false)
    }

    updateProductsInCart(products)
  },
  cleanCart() {
    updateProductsInCart([])
    store.set('cart/client', null)
    store.set('cart/billingInfo', null)
    store.set('cart/address', null)
    store.set('cart/paymentMethod', null)
    store.set('cart/shippingMethod', null)
  }
}

export default actions
