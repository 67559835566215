<template>
  <div v-if="addProductWhithVariantsModalIsOpen && product" class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-content">
      <h1>Agregar al carrito de compras</h1>
      <div class="columns">
        <div class="column is-3 product-img">
          <img v-if="product.mainImage" :src="product.mainImage" />
          <img v-else src="@/assets/icon-item-default.svg" />
        </div>
        <div class="column is-9">
          <h2>{{ product.name }}</h2>
          <div class="container-variants">
            <app-variant-product
              v-if="product.variantAttributes"
              :product="product"
              :errorMessage="errorMessage"
              @updateVariantsSelected="updateVariantsSelected"
            />
            <app-loading v-else :loading="true" size="medium" />
          </div>
        </div>
      </div>
      <button
        class="button is-fullwidth go-cart"
        @click="addToCart()"
        :disabled="disabledAddToCart"
        :class="{ 'is-loading': adding }"
      >
        AGREGAR AL CARRITO
      </button>
      <button class="button is-fullwidth close" @click="closeModal(true)">SEGUIR COMPRANDO</button>
    </div>
    <button class="modal-close is-large" aria-label="close" @click="closeModal()"></button>
  </div>
</template>

<script>
import store from '@/store'
import { get } from 'vuex-pathify'
import { mapGetters, mapActions } from 'vuex'
import variantProductUtil from '@/utils/variantProduct'

import AppVariantProduct from '@/components/shared/VariantProduct'
import AppLoading from '@/components/shared/Loading'

export default {
  components: {
    AppVariantProduct,
    AppLoading
  },
  data() {
    return {
      product: null,
      errorMessage: '',
      timeOutError: null,
      variantsSelected: [],
      adding: false
    }
  },
  computed: {
    ...mapGetters('config', ['mode']),
    addProductWhithVariantsModalIsOpen: get('modal@addProductWhithVariantsModal.isOpen'),
    disabledAddToCart() {
      return this.adding || !this.product.variantAttributes
    },
    queryParams() {
      const query = {}
      if (this.mode) query.mode = this.mode
      return query
    }
  },
  watch: {
    addProductWhithVariantsModalIsOpen(val) {
      this.product = store.get('modal/addProductWhithVariantsModal@product')
      if (val && this.product) {
        this.loadServerProduct(this.product.id)
      }
    }
  },
  methods: {
    ...mapActions('products', ['getProductById']),
    ...mapActions('cart', ['addProductCart']),
    closeModal(home = false) {
      store.set('modal/addProductWhithVariantsModal@isOpen', false)
      if (home) {
        this.$router.push({ name: 'home', query: this.queryParams })
      }
    },
    addToCart() {
      const { status, variant, errorMsg } = variantProductUtil.searchVariant(this.product, this.variantsSelected)
      if (status === 'SUCCESS') {
        this.adding = true
        this.addProductCart({ product: variant, quantity: 1 })
        store.set('modal/addProductWhithVariantsModal@isOpen', false)
        this.adding = false
      } else {
        this.errorMessage = errorMsg
        clearTimeout(this.timeOutError)
        this.timeOutError = setTimeout(() => {
          this.errorMessage = ''
        }, 4000)
      }
    },
    updateVariantsSelected(val) {
      this.variantsSelected = val
    },
    loadServerProduct(idProduct) {
      this.getProductById({ idProduct })
        .then(product => {
          this.product = product
        })
        .catch(e => {
          if (e.status === 404) {
            this.$router.replace({ name: '404', query: this.queryParams })
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-content {
  background-color: #fff;
  padding: 15px 20px;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  width: 500px;
  h1 {
    font-size: 20px;
    color: #29303d;
    margin-bottom: 30px;
  }
  h2 {
    font-size: 18px;
    color: #47536b;
    margin-bottom: 20px;
  }
  .quantity {
    margin-bottom: 10px;
  }
  .price {
    font-size: 19px;
    color: #47536b;
  }
  .product-img {
    justify-content: center;
    display: flex;
    img {
      min-height: 80px;
      max-height: 100px;
      max-width: 100%;
    }
  }
  .button:first-child {
    margin-bottom: 8px;
  }
  .button.go-cart {
    background: #29303d;
    border: 1px solid #29303d;
    box-sizing: border-box;
    color: #fff;
    font-size: 14px;
    border-radius: 0;
    line-height: 24px;
    text-transform: uppercase;
    height: 36px;
  }
  .button.close {
    color: #29303d;
    border: 0;
    font-size: 14px;
    line-height: 24px;
    height: 36px;
  }
  .container-variants {
    width: 65%;
    min-height: 100px;
  }
}
@media (max-width: 768px) {
  .modal-content {
    width: 80%;
    h1 {
      font-size: 20px;
    }
  }
}
</style>
