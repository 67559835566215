var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Header', [_c('div', {
    staticClass: "level"
  }, [_c('div', {
    staticClass: "level-left"
  }, [_vm.shop ? _c('div', {
    staticClass: "level-item"
  }, [_c('inline-svg', {
    staticClass: "pointer",
    attrs: {
      "src": require('@/assets/icon-menu.svg')
    },
    on: {
      "click": function ($event) {
        return _vm.openMenu();
      }
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "level-center"
  }, [_vm.shop ? _c('div', {
    staticClass: "level-item"
  }, [_c('router-link', {
    staticClass: "link-logo",
    attrs: {
      "to": {
        name: 'home',
        query: _vm.queryParams
      }
    }
  }, [_vm.shopLogo ? _c('img', {
    staticClass: "image-logo",
    attrs: {
      "src": _vm.shopLogo
    }
  }) : _c('h2', {
    staticClass: "title-shop"
  }, [_vm._v(_vm._s(_vm.shop.name))])])], 1) : _vm._e()]), _c('div', {
    staticClass: "level-right"
  }, [_c('div', {
    staticClass: "level-item"
  }, [_c('div', {
    staticClass: "shopping-car pointer",
    on: {
      "click": _vm.goToShoppingCart
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icon-shopping-car.svg')
    }
  }), _vm.productsInCart.length ? _c('div', {
    staticClass: "badge has-text-centered"
  }, [_c('p', [_vm._v(_vm._s(_vm.unitsInCart))])]) : _vm._e()], 1)])])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$route.name === 'home' || _vm.$route.name === 'category',
      expression: "$route.name === 'home' || $route.name === 'category'"
    }],
    staticClass: "wrapper-search"
  }, [_c('app-input-search')], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }