<template>
  <section>
    <app-loading :loading="!paymentMethods" />

    <div v-if="conventionalPayments.length">
      <div class="subtitle">
        <h2>ELIGE TU MÉTODO DE PAGO</h2>
      </div>
      <div class="payment-methods">
        <template v-for="(payment, index) in conventionalPayments">
          <app-payment-option
            v-if="isPaymentMethodVisible(payment.provider)"
            :key="index"
            :option="payment"
            :selected="paymentMethod?.id === payment.id"
            @select-payment="paymentId => selectPayment(paymentId, 'conventional')"
          />
        </template>
      </div>
    </div>

    <div v-if="otherPayments.length">
      <div class="subtitle">
        <h2>OTROS MÉTODOS</h2>
      </div>
      <div class="payment-methods">
        <app-payment-option
          v-for="(payment, index) in otherPayments"
          :key="index"
          :option="payment"
          :selected="paymentMethod?.id === payment.id"
          @select-payment="paymentId => selectPayment(paymentId, 'other')"
        />
      </div>
    </div>

    <div class="shipping-methods">
      <div class="subtitle">
        <span class="icon">
          <img src="@/assets/icon-truck.svg" />
        </span>
        <h2>COSTO DEL ENVÍO</h2>
      </div>
      <app-loading v-if="loadingShipping" loading size="medium" />
      <div v-else class="shipping-methods-container">
        <app-shipping-option
          v-for="shipping in availableShippingMethods"
          :key="shipping.id"
          :id="shipping.id"
          :name="shipping.name"
          :description="shipping.description"
          :price="shipping.rate || 0"
          :selected="shippingMethod?.id === shipping.id"
          @select-shipping="selectShipping"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import store from '@/store'

import AppPaymentOption from '@/components/shopping-cart/payment/PaymentOption'
import AppShippingOption from '@/components/shopping-cart/shipping/ShippingOption'
import AppLoading from '@/components/shared/Loading'

export default {
  components: {
    AppPaymentOption,
    AppShippingOption,
    AppLoading
  },
  mounted() {
    this.getPaymentMethods()
  },
  computed: {
    ...mapGetters('shipping', ['availableShippingMethods', 'loadingShipping']),
    ...mapGetters('shop', ['conventionalPayments', 'otherPayments']),
    ...mapState('shop', ['paymentMethods']),
    ...mapGetters('cart', ['subtotal', 'paymentMethod', 'shippingMethod'])
  },
  methods: {
    ...mapActions('shop', ['getPaymentMethods']),
    selectPayment(paymentId, type) {
      let payment = {}
      if (type === 'conventional') {
        payment = this.conventionalPayments.find(({ id }) => id === paymentId)
      } else {
        payment = this.otherPayments.find(({ id }) => id === paymentId)
      }
      store.set('cart/paymentMethod', payment)
    },
    selectShipping(shippingId) {
      const shippingMethod = this.availableShippingMethods.find(({ id }) => id === shippingId)
      store.set('cart/shippingMethod', shippingMethod)
    },
    isPaymentMethodVisible(provider) {
      if (provider === 'PAYU') {
        const paymentMethod = this.paymentMethods.find(pm => pm.provider === provider)
        const isEnableMinAmount = paymentMethod.providerConfig.min_amount_option
        const minAmount = paymentMethod.providerConfig.min_amount
        if (isEnableMinAmount && this.subtotal < minAmount) return false
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.loader-wrapper {
  margin-top: 100px;
}
.subtitle {
  display: flex;
  align-items: center;
  margin-left: 14px;
  margin-top: 30px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #47536b;
  .icon {
    margin-right: 10px;
  }
}
.shipping-methods {
  display: flex;
  flex-direction: column;
}
.shipping-methods-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}
@media (min-width: 641px) {
  .shipping-methods-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .loader-wrapper {
    margin: 20px 0;
  }
}
</style>
