var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('app-loading', {
    attrs: {
      "loading": !_vm.paymentMethods
    }
  }), _vm.conventionalPayments.length ? _c('div', [_vm._m(0), _c('div', {
    staticClass: "payment-methods"
  }, [_vm._l(_vm.conventionalPayments, function (payment, index) {
    var _vm$paymentMethod;
    return [_vm.isPaymentMethodVisible(payment.provider) ? _c('app-payment-option', {
      key: index,
      attrs: {
        "option": payment,
        "selected": ((_vm$paymentMethod = _vm.paymentMethod) === null || _vm$paymentMethod === void 0 ? void 0 : _vm$paymentMethod.id) === payment.id
      },
      on: {
        "select-payment": function (paymentId) {
          return _vm.selectPayment(paymentId, 'conventional');
        }
      }
    }) : _vm._e()];
  })], 2)]) : _vm._e(), _vm.otherPayments.length ? _c('div', [_vm._m(1), _c('div', {
    staticClass: "payment-methods"
  }, _vm._l(_vm.otherPayments, function (payment, index) {
    var _vm$paymentMethod2;
    return _c('app-payment-option', {
      key: index,
      attrs: {
        "option": payment,
        "selected": ((_vm$paymentMethod2 = _vm.paymentMethod) === null || _vm$paymentMethod2 === void 0 ? void 0 : _vm$paymentMethod2.id) === payment.id
      },
      on: {
        "select-payment": function (paymentId) {
          return _vm.selectPayment(paymentId, 'other');
        }
      }
    });
  }), 1)]) : _vm._e(), _c('div', {
    staticClass: "shipping-methods"
  }, [_vm._m(2), _vm.loadingShipping ? _c('app-loading', {
    attrs: {
      "loading": "",
      "size": "medium"
    }
  }) : _c('div', {
    staticClass: "shipping-methods-container"
  }, _vm._l(_vm.availableShippingMethods, function (shipping) {
    var _vm$shippingMethod;
    return _c('app-shipping-option', {
      key: shipping.id,
      attrs: {
        "id": shipping.id,
        "name": shipping.name,
        "description": shipping.description,
        "price": shipping.rate || 0,
        "selected": ((_vm$shippingMethod = _vm.shippingMethod) === null || _vm$shippingMethod === void 0 ? void 0 : _vm$shippingMethod.id) === shipping.id
      },
      on: {
        "select-shipping": _vm.selectShipping
      }
    });
  }), 1)], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "subtitle"
  }, [_c('h2', [_vm._v("ELIGE TU MÉTODO DE PAGO")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "subtitle"
  }, [_c('h2', [_vm._v("OTROS MÉTODOS")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "subtitle"
  }, [_c('span', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon-truck.svg")
    }
  })]), _c('h2', [_vm._v("COSTO DEL ENVÍO")])]);
}]

export { render, staticRenderFns }