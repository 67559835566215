import store from '@/store'
import { VERSIONS } from '@/utils/constants'

const getters = {
  totalShipping() {
    const shippingMethod = store.getters['cart/shippingMethod']

    if (!shippingMethod) return 0

    const minPurchaseForFree = parseFloat(shippingMethod.minPurchaseForFree || 0)
    const rate = parseFloat(shippingMethod.rate || 0)
    const subtotal = parseFloat(store.getters['cart/subtotal'] || 0)

    if (minPurchaseForFree && subtotal >= minPurchaseForFree) return 0

    return rate
  },
  availableShippingMethods(state) {
    if (state.shippingMethods && state.shippingMethods.length > 0) {
      return state.shippingMethods
    }
    return null
  },
  activeCityShipping() {
    const applicationVersion = store.get('shop/shop@config.applicationVersion')

    const { COLOMBIA, REPUBLICA_DOMINICANA, PANAMA, PERU, COSTA_RICA, ARGENTINA, CHILE } = VERSIONS
    const activeCityShippingVersions = [
      COLOMBIA,
      REPUBLICA_DOMINICANA,
      PANAMA,
      PERU,
      COSTA_RICA,
      ARGENTINA,
      CHILE
    ]

    if (activeCityShippingVersions.includes(applicationVersion)) {
      return true
    }

    return false
  }
}

export default getters
