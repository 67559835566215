<template>
  <section>
    <div class="personal-info">
      <div class="level header-shipping">
        <div class="level-item">
          <span class="icon">
            <img src="@/assets/icon-user.svg" />
          </span>
          <h2 class="subtitle">TUS DATOS</h2>
        </div>
      </div>
      <form>
        <div class="field">
          <label class="label">Tu nombre *</label>
          <div class="control">
            <input
              v-model.trim="$v.client.name.$model"
              class="input"
              :class="{ error: $v.client.name.$invalid && $v.client.name.$dirty }"
              type="text"
              placeholder="Escribe tu nombre"
              @input="changeInput('client', 'name')"
            />
          </div>
          <div v-if="$v.client.name.$dirty">
            <p v-if="!$v.client.name.required" class="help is-danger">Ingresa tu nombre</p>
          </div>
        </div>
        <div>
          <identification-client />
        </div>
        <div class="columns">
          <div class="column is-7">
            <div class="field">
              <label class="label">Correo electrónico *</label>
              <div class="control">
                <input
                  v-model.trim="$v.client.email.$model"
                  class="input"
                  :class="{ error: $v.client.email.$invalid && $v.client.email.$dirty }"
                  type="email"
                  placeholder="Tu correo electrónico"
                  @input="changeInput('client', 'email')"
                />
              </div>
              <div v-if="$v.client.email.$dirty">
                <p v-if="!$v.client.email.required" class="help is-danger">Ingresa tu email</p>
                <p v-if="!$v.client.email.email" class="help is-danger">Email invalido</p>
              </div>
            </div>
          </div>
          <div class="column is-5">
            <div class="field">
              <label class="label">Teléfono *</label>
              <div class="control">
                <input
                  v-model.trim="$v.client.phone.$model"
                  class="input"
                  :class="{ error: $v.client.phone.$invalid && $v.client.phone.$dirty }"
                  @input="changeInput('client', 'phone')"
                  type="number"
                  placeholder="Tu teléfono de contacto"
                />
              </div>
              <div v-if="$v.client.phone.$dirty">
                <p v-if="!$v.client.phone.required" class="help is-danger">
                  Ingresa tu telefono de contacto
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="shipping-info">
      <div class="level header-shipping">
        <div class="level-item">
          <span class="icon">
            <img src="@/assets/icon-truck.svg" />
          </span>
          <h2 class="subtitle">DATOS DE ENVÍO</h2>
        </div>
      </div>
      <form>
        <div class="columns">
          <div class="column" :class="activeCityShipping ? 'is-12' : 'is-6'">
            <div class="field">
              <label class="label">Dirección *</label>
              <div class="control">
                <input
                  v-model.trim="$v.address.address.$model"
                  class="input"
                  :class="{ error: $v.address.address.$invalid && $v.address.address.$dirty }"
                  @input="changeInput('address', 'address')"
                  type="text"
                  placeholder="Dirección de envío"
                />
              </div>
              <div v-if="$v.address.address.$dirty">
                <p v-if="!$v.address.address.required" class="help is-danger">
                  Ingresa una dirección
                </p>
              </div>
            </div>
          </div>

          <div v-if="!activeCityShipping" class="column is-6">
            <div class="field">
              <label class="label">Ciudad *</label>
              <div class="control">
                <input
                  v-model.trim="$v.address.city.$model"
                  :class="{ error: $v.address.city.$invalid && $v.address.city.$dirty }"
                  class="input"
                  type="text"
                  placeholder="Ciudad, País"
                />
              </div>
              <div v-if="$v.address.city.$dirty">
                <p v-if="!$v.address.city.required" class="help is-danger">Ingresa una ciudad</p>
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div v-if="$v.address.zipcode" class="column is-4">
            <div class="field">
              <label class="label">
                Código postal {{ !$v.address.zipcode.required ? '*' : '' }}
              </label>
              <div class="control">
                <input
                  v-model.trim="$v.address.zipcode.$model"
                  :class="{ error: $v.address.zipcode.$invalid && $v.address.zipcode.$dirty }"
                  class="input"
                  type="text"
                  placeholder="Código postal (ZIP)"
                />
              </div>
              <div v-if="$v.address.zipcode.$dirty">
                <p v-if="!$v.address.zipcode.required" class="help is-danger">
                  Ingresa tu código postal
                </p>
                <p v-if="!$v.address.zipcode.minLength || !$v.address.zipcode.maxLength" class="help is-danger">
                  Ingresa un código postal válido
                </p>
              </div>
            </div>
          </div>
        </div>

        <city-field v-if="activeCityShipping" />

        <div class="field">
          <label class="label">Información adicional (opcional)</label>
          <div class="control">
            <input
              v-model="address.additionalInfo"
              class="input"
              type="text"
              placeholder="Información adicional"
            />
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { get } from 'vuex-pathify'
import EventBus from '@/eventBus'
import { required, email, numeric, minLength, maxLength } from 'vuelidate/lib/validators'
import store from '@/store'
import { VERSIONS } from '@/utils/constants'
import { isValidDNIPeru, isValidRncDom, isValidCedDom } from '@/utils/vuelidateValidations'
import CityField from './shipping/CityField.vue'
import IdentificationClient from './client/IdentificationClient.vue'

const { COSTA_RICA, COLOMBIA, ARGENTINA, PERU, MEXICO, REPUBLICA_DOMINICANA } = VERSIONS

export default {
  name: 'OrderShipping',
  components: {
    CityField,
    IdentificationClient
  },
  provide() {
    return {
      v: this.$v
    }
  },
  data() {
    return {
      client: {
        name: null,
        email: null,
        phone: null
      },
      address: {
        address: null,
        department: null,
        city: null,
        province: null,
        zipcode: null,
        additionalInfo: null
      },
      billingInfo: {
        identification: null,
        identificationType: null
      }
    }
  },
  validations() {
    const val = {
      client: {
        name: { required },
        email: { required, email },
        phone: { required }
      },
      address: {
        address: { required },
        city: { required },
        department: {}
      },
      billingInfo: {}
    }

    if (this.activeCityShipping) {
      val.address.department = { required }
    }

    if ([COLOMBIA, ARGENTINA, PERU, MEXICO, REPUBLICA_DOMINICANA, COSTA_RICA].includes(this.applicationVersion)) {
      val.billingInfo.identification = { required, numeric }
    }
    if ([COLOMBIA, ARGENTINA, PERU, REPUBLICA_DOMINICANA, COSTA_RICA].includes(this.applicationVersion)) {
      val.billingInfo.identificationType = { required }
    }
    if (this.applicationVersion === PERU) {
      val.billingInfo.identification.isValidDNIPeru = isValidDNIPeru
    }
    if (this.applicationVersion === MEXICO) {
      val.address.zipcode = { required, minLength: minLength(5), maxLength: maxLength(5) }
    }
    if (this.applicationVersion === REPUBLICA_DOMINICANA) {
      val.billingInfo.identification.isValidRncDom = isValidRncDom
      val.billingInfo.identification.isValidCedDom = isValidCedDom
    }

    return val
  },
  watch: {
    'address.department': function(selectedDepartment) {
      if ([COSTA_RICA].includes(this.applicationVersion)) {
        this.getDistricts({ departmentId: selectedDepartment.id })
      } else {
        this.getCities({ departmentId: selectedDepartment.id })
      }
    }
  },
  computed: {
    ...mapGetters('shipping', ['activeCityShipping']),
    ...mapGetters('cart', ['subtotal']),
    applicationVersion: get('shop@shop.config.applicationVersion')
  },
  mounted() {
    EventBus.$on('VALIDATE_DATA_SHIPPING', () => {
      this.saveForm()
    })
  },
  beforeDestroy() {
    EventBus.$off('VALIDATE_DATA_SHIPPING')
  },
  methods: {
    ...mapActions('config', ['getCities', 'getDistricts']),
    saveForm() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        store.set('cart/client', this.client)

        const address = { ...this.address }
        if (address.city.value) {
          address.province = address.department.value
          address.city = address.city.value
        }
        store.set('cart/address', address)

        const billingInfo = { ...this.billingInfo }
        if (billingInfo.identificationType?.key) {
          billingInfo.identificationType = billingInfo.identificationType.key
        }
        store.set('cart/billingInfo', billingInfo)

        EventBus.$emit('NEXT_STEP_CART', { step: 'PAYMENT_METHOD' })
      }
    },
    changeInput(entity, value) {
      this.$v[entity][value].$touch()
    }
  }
}
</script>

<style lang="scss" scoped>
.personal-info {
  margin-top: 30px;
}
.shipping-info {
  margin: 30px 0 30px 0;
}
.subtitle {
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  color: #47536b;
  margin-left: 14px;
}
.level-item {
  justify-content: flex-start;
}
::v-deep .label {
  font-size: 12px;
  line-height: 18px;
  color: #8592ad;
}
::v-deep .input {
  border: 0;
  border-bottom: 1px solid #cccccc;
  border-radius: 0;
  box-shadow: none;
  padding-left: 0;
  font-size: 14px;
  line-height: 21px;
  color: #47536b;
}
::v-deep select {
  border: 0;
  border-bottom: 1px solid #cccccc;
  border-radius: 0;
  box-shadow: none;
  padding-left: 0;
  font-size: 14px;
  line-height: 21px;
  color: #47536b;
  width: 100%;
}
::v-deep .input.error {
  border-bottom: 1px solid #f14668;
}
::v-deep ::placeholder {
  color: #c2c9d6;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}
</style>
