<template>
  <div class="cart-product">
    <div class="cart-product__image-name">
      <div class="cart-product__image">
        <img v-if="product.mainImage" :src="product.mainImage" />
        <img v-else src="@/assets/icon-item-default.svg" />
      </div>
      <div class="cart-product__name">
        <p v-if="product.hasNoIvaDays" class="label-no-iva-day">Sin IVA</p>
        <router-link :to="productPageRoute">
          {{ product.name }}
        </router-link>
      </div>
    </div>
    <div class="cart-product__quantity">
      <AppMoreOrLessProduct
        :quantity="product.quantity"
        :max-quantity="stockQuantity"
        :sell-without-stock="sellWithoutStock"
        :product="product"
        @updateQuantity="updatedQuantity => setQuantity(updatedQuantity)"
      />
      <p v-if="shouldShowNoStockError" class="cart-product__quantity__no-stock">
        {{ insufficientStockMessage }}
      </p>
    </div>
    <div class="cart-product__price">
      <p>{{ $currencyFormatter(product.finalPrice) }}</p>
      <p
        v-if="product.finalPriceBeforeDiscounts != product.finalPrice"
        class="price-before-discount"
      >
        {{ $currencyFormatter(product.finalPriceBeforeDiscounts) }}
      </p>
    </div>
    <div class="cart-product__remove" @click="remove()">
      <img src="@/assets/icon-trash.svg" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import urlUtil from '@/utils/url'
import AppMoreOrLessProduct from '@/components/shared/MoreOrLessProduct'

export default {
  name: 'CartProduct',
  components: {
    AppMoreOrLessProduct
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      shouldShowNoStockError: false,
      stockQuantity: null,
      sellWithoutStock: true
    }
  },
  computed: {
    ...mapState('products', ['productsInCartInventory']),
    ...mapGetters('config', ['mode']),
    insufficientStockMessage() {
      let message = 'No hay unidades disponibles en este momento.'

      if (this.stockQuantity > 0) {
        message = `Hay ${this.stockQuantity} unidades disponibles.`
      }

      return message
    },
    queryParams() {
      const query = {}
      if (this.mode) query.mode = this.mode
      return query
    },
    productPageRoute() {
      const { name, idVariantParent, id, category } = this.product
      return {
        name: 'product',
        params: {
          product: this.formatUrl(name) + '-' + (idVariantParent || id),
          category: category ? `${urlUtil.formatSeoUrl(category.name)}-${category.id}` : 'all'
        },
        query: this.queryParams
      }
    }
  },
  watch: {
    productsInCartInventory: {
      deep: true,
      handler(productsInCartInventory) {
        this.checkInventoryCart(productsInCartInventory)
      }
    }
  },
  methods: {
    ...mapActions('cart', ['updateQuantityProductSelected', 'removeProduct']),
    checkInventoryCart(productsInCartInventory) {
      const productInventory = productsInCartInventory.find(
        productInventory => this.product.id === productInventory.id
      )

      const { inventory, inventorySettings } = productInventory

      const shouldCheckForStock = inventory && !inventorySettings?.sellWithoutStock
      const hasInsufficientStock = inventorySettings?.quantity < this.product.quantity
      const shouldShowNoStockError = shouldCheckForStock && hasInsufficientStock

      this.shouldShowNoStockError = shouldShowNoStockError
      this.stockQuantity = inventorySettings?.sellWithoutStock ? null : inventorySettings?.quantity

      if (shouldShowNoStockError) {
        this.product.quantity = inventorySettings?.quantity > 0 ? inventorySettings?.quantity : 1
        this.sellWithoutStock = inventorySettings?.sellWithoutStock
      }
    },
    setQuantity(updatedQuantity) {
      this.updateQuantityProductSelected({ updatedQuantity, productToUpdate: this.product })
    },
    remove() {
      this.removeProduct(this.product)
    },
    formatUrl(val) {
      return urlUtil.formatSeoUrl(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.cart-product {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ededed;
  padding: 30px 15px;
  flex-wrap: wrap;
  position: relative;

  @media (max-width: 1300px) {
    padding: 30px 5px;
  }

  &__image-name {
    padding-right: 15px;
    flex-basis: 40%;
    display: flex;
    align-items: center;

    @media (max-width: 1300px) {
      flex-basis: 75%;
    }
  }

  &__image {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    margin-right: 20px;

    @media (max-width: 1300px) {
      margin-right: 20px;
      width: 70px;
      height: 70px;
    }

    img {
      height: 100%;
      max-width: 100%;
    }
  }

  &__name {
    line-height: 18px;

    a {
      color: #707070;
      font-size: 12px;

      &:hover {
        text-decoration: underline;
      }
    }

    .label-no-iva-day {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      color: #00b19d;
      margin-bottom: 5px;
    }
  }

  &__quantity {
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    @media (max-width: 1300px) {
      margin-top: 15px;
      order: 1;
    }

    &__no-stock {
      font-size: 12px;
      text-align: center;
      color: #be123c;
      line-height: 12px;
      width: 170px;
      position: absolute;
      top: 47px;

      @media (max-width: 1300px) {
        width: max-content;
        left: 0;
        top: 50px;
      }
    }
  }

  &__price {
    color: #707070;
    margin-left: auto;
    flex-basis: 25%;

    @media (max-width: 1300px) {
      flex-basis: 25%;
      text-align: right;
      order: 0;
    }

    .price-before-discount {
      text-decoration-line: line-through;
      margin-top: -5px;
      color: #c2c9d6;
      font-size: 11px;
    }
  }

  &__remove {
    cursor: pointer;
    position: relative;

    @media (max-width: 1300px) {
      order: 2;
    }

    img {
      width: 24x;
      height: 24px;
    }

    &::before {
      content: '';
      position: absolute;
      padding: 30px;
      bottom: -12px;
      right: -18px;
    }
  }
}
</style>
