<template>
  <div class="container">
    <h1>404</h1>
    <h2>Página no encontrada</h2>
    <p>Te invitamos a ver nuestros productos</p>
    <router-link
      class="button"
      :to="{
        name: 'home',
        query: queryParams
      }"
    >
      VER PRODUCTOS
    </router-link>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  metaInfo() {
    const title = 'Tienda - Página no encontrada'
    const description = 'Página no encontrada'
    // const title = `${(this.shop ? this.shop.name + ' - ' : '')}Error 404 (Page not found)`
    return {
      title,
      meta: [
        { name: 'title', content: title },
        { property: 'og:title', content: title },
        { name: 'description', content: description },
        { property: 'og:description', content: description },
        { property: 'og:site_name', content: title },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `https://${this.shop.domain}${this.$route.path}` },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  },
  computed: {
    ...mapState('shop', ['shop']),
    ...mapGetters('config', ['mode']),
    queryParams() {
      const query = {}
      if (this.mode) query.mode = this.mode
      return query
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  text-align: center;
  color: #a3adc2;
}
h1 {
  font-size: 50px;
}
h2 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 40px;
}
p {
  margin-bottom: 20px;
}
.button {
  background: #29303D;
  border: 1px solid #29303D;
  box-sizing: border-box;
  color: #FFF;
  font-size: 14px;
  border-radius: 0;
  line-height: 24px;
  text-transform: uppercase;
}
</style>
