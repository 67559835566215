var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "columns-collection"
  }, [_vm.isMobile && _vm.showDetail || !_vm.isMobile ? _c('div', {
    staticClass: "columns-products"
  }, _vm._l(_vm.products, function (product, index) {
    return _c('div', {
      key: index,
      staticClass: "columns is-mobile"
    }, [_c('div', {
      staticClass: "column is-7 column-name"
    }, [_c('p', [_vm._v(" " + _vm._s(product.name) + " "), _c('strong', [_vm._v("X " + _vm._s(product.quantity))])])]), _c('div', {
      staticClass: "column has-text-right column-price"
    }, [_vm.hasDiscount(product) ? _c('p', [_vm._v(" " + _vm._s(_vm.$currencyFormatter(_vm.individualPriceWithDiscountAndTaxes(product))) + " ")]) : _vm._e(), _c('p', {
      class: {
        'price-defore-discount': _vm.hasDiscount(product)
      }
    }, [_vm._v(" " + _vm._s(_vm.$currencyFormatter(_vm.individualPriceWithTaxes(product))) + " ")])])]);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "footer-invoice"
  }, [_vm.shouldShowCouponSection ? _c('div', {
    staticClass: "footer-invoice__coupon-section"
  }, [_c('CouponSection', {
    attrs: {
      "current-step": _vm.step
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "footer-invoice__pricing-section"
  }, [_vm.isMobile && _vm.showDetail || !_vm.isMobile ? _c('div', {
    staticClass: "data"
  }, [_vm.hasNoIvaDay ? _c('div', {
    staticClass: "warningHasNoIvaDays"
  }, [_c('h5', [_vm._v("¡Ten presente!")]), _vm._m(0)]) : _vm._e(), _c('div', {
    staticClass: "invoice-amount-field"
  }, [_c('div', [_vm._v("Subtotal")]), _c('div', [_vm._v(" " + _vm._s(_vm.$currencyFormatter(_vm.subtotal)) + " ")])]), _vm.appliedCoupon ? _c('div', {
    staticClass: "invoice-amount-field"
  }, [_c('div', [_vm._v("Descuento " + _vm._s(_vm.appliedCoupon.code))]), _c('div', [_vm._v("-" + _vm._s(_vm.$currencyFormatter(_vm.appliedCouponDiscountedPrice)))])]) : _vm._e(), _vm.shouldShowShippingCost ? _c('div', {
    staticClass: "invoice-amount-field"
  }, [_c('div', [_vm._v("Costo de envío")]), _c('div', [typeof _vm.validTotalShipping === 'number' ? _c('span', [_vm._v(" " + _vm._s(_vm.$currencyFormatter(_vm.validTotalShipping)) + " ")]) : _c('span', [_vm._v("---")])])]) : _vm._e(), _c('div', {
    staticClass: "columns is-mobile total"
  }, [_c('div', {
    staticClass: "column"
  }, [_vm._v("Total")]), _c('div', {
    staticClass: "column has-text-right"
  }, [_vm._v(" " + _vm._s(_vm.$currencyFormatter(_vm.validTotal)) + " ")])])]) : _vm._e(), _vm.infoBannerTxt ? _c('info-banner', {
    attrs: {
      "text": _vm.infoBannerTxt
    }
  }) : _vm._e(), _c('div', [_c('button', {
    staticClass: "button is-fullwidth button-next",
    class: _vm.buttonClasses,
    attrs: {
      "disabled": _vm.shouldDisableButton
    },
    on: {
      "click": _vm.nextStep
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.textButtonNext))])])])], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" Solo se cobrarán sin IVA "), _c('span', [_vm._v("3 unidades")]), _vm._v(" por categoría, si adquiriste una cantidad mayor tu factura llegará por un valor más alto. ")]);
}]

export { render, staticRenderFns }