import store from '@/store'
import { formatCurrencyForCalculations } from '@/utils/currency'
import Big from 'big.js'
import { VERSIONS } from '@/utils/constants'

const getters = {
  subtotal(state) {
    const productsInCart = state.productsInCart.length
      ? state.productsInCart
      : state.lastOrderProductsInCart

    const applicationVersion = store.get('shop/shop@config.applicationVersion')

    if (productsInCart.length) {
      const totalPrice = productsInCart
        .map(product => {
          let productDiscount = product.discounts?.discountPercentage
          productDiscount = productDiscount
            ? formatCurrencyForCalculations(productDiscount / 100)
            : 0

          const discountValue = product.price * product.quantity * productDiscount
          const subtotal = product.price * product.quantity - discountValue
          let totalTaxes = 0

          if (applicationVersion === VERSIONS.MEXICO) {
            let ivaPercentage = 0
            let iepsPercentage = 0

            product.taxes.forEach((tax) => {
              if (tax.type === 'IEPS') {
                iepsPercentage = tax.percentage
              } else if (tax.type === 'IVA') {
                ivaPercentage = tax.percentage
              }
            })

            if (product.taxes.length === 2 && iepsPercentage > 0 && ivaPercentage > 0) {
              const iepsValue = subtotal * (iepsPercentage / 100)
              const ivaBase = subtotal + iepsValue
              const ivaValue = ivaBase * (ivaPercentage / 100)
              totalTaxes = iepsValue + ivaValue
            } else {
              totalTaxes = product.taxes.reduce((acc, tax) => {
                return acc + subtotal * (tax.percentage / 100)
              }, 0)
            }
          } else {
            totalTaxes = product.taxes.reduce((acc, tax) => {
              return acc + subtotal * (tax.percentage / 100)
            }, 0)
          }

          return formatCurrencyForCalculations(subtotal + totalTaxes)
        })
        .reduce((acc, bill) => formatCurrencyForCalculations(Big(bill).plus(acc)))

      return totalPrice
    }
    return 0
  },
  unitsInCart(state) {
    const productsInCart = state.productsInCart.length
      ? state.productsInCart
      : state.lastOrderProductsInCart

    if (productsInCart.length) {
      return productsInCart.map(product => product.quantity).reduce((acc, bill) => bill + acc)
    }
    return 0
  },
  appliedCouponDiscountedPrice(state, getters) {
    const productsInCart = state.productsInCart.length
      ? state.productsInCart
      : state.lastOrderProductsInCart
    const appliedCoupon = store.get('coupons/appliedCoupon')
    const applicationVersion = store.get('shop/shop@config.applicationVersion')

    if (productsInCart.length) {
      const sumOfPriceWithCouponAndDiscount = productsInCart
        .map(product => {
          let productDiscount = product.discounts?.discountPercentage
          productDiscount = productDiscount
            ? productDiscount / 100
            : 0
          let couponDiscount = appliedCoupon?.discountPercentage
          couponDiscount = couponDiscount
            ? couponDiscount / 100
            : 0

          const totalDiscounts = productDiscount + couponDiscount
          const discountValue = product.price * product.quantity * totalDiscounts
          const subtotal = product.price * product.quantity - discountValue
          let totalTaxes

          if (applicationVersion === VERSIONS.MEXICO) {
            let ivaPercentage = 0
            let iepsPercentage = 0

            product.taxes.forEach((tax) => {
              if (tax.type === 'IEPS') {
                iepsPercentage = tax.percentage
              } else if (tax.type === 'IVA') {
                ivaPercentage = tax.percentage
              }
            })

            if (product.taxes.length === 2 && iepsPercentage > 0 && ivaPercentage > 0) {
              const iepsValue = subtotal * (iepsPercentage / 100)
              const ivaBase = subtotal + iepsValue
              const ivaValue = ivaBase * (ivaPercentage / 100)
              totalTaxes = iepsValue + ivaValue
            } else {
              totalTaxes = product.taxes.reduce((acc, tax) => {
                return acc + subtotal * (tax.percentage / 100)
              }, 0)
            }
          } else {
            totalTaxes = product.taxes.reduce((acc, tax) => {
              return acc + subtotal * (tax.percentage / 100)
            }, 0)
          }

          return formatCurrencyForCalculations(subtotal + totalTaxes)
        })
        .reduce((acc, bill) => formatCurrencyForCalculations(Big(bill).plus(acc)))

      const sumOfPriceWithDiscount = productsInCart
        .map(product => {
          let productDiscount = product.discounts?.discountPercentage
          productDiscount = productDiscount
            ? formatCurrencyForCalculations(productDiscount / 100)
            : 0

          const discountValue = product.price * product.quantity * productDiscount
          const subtotal = product.price * product.quantity - discountValue
          let totalTaxes

          if (applicationVersion === VERSIONS.MEXICO) {
            let ivaPercentage = 0
            let iepsPercentage = 0

            product.taxes.forEach((tax) => {
              if (tax.type === 'IEPS') {
                iepsPercentage = tax.percentage
              } else if (tax.type === 'IVA') {
                ivaPercentage = tax.percentage
              }
            })

            if (product.taxes.length === 2 && iepsPercentage > 0 && ivaPercentage > 0) {
              const iepsValue = subtotal * (iepsPercentage / 100)
              const ivaBase = subtotal + iepsValue
              const ivaValue = ivaBase * (ivaPercentage / 100)
              totalTaxes = iepsValue + ivaValue
            } else {
              totalTaxes = product.taxes.reduce((acc, tax) => {
                return acc + subtotal * (tax.percentage / 100)
              }, 0)
            }
          } else {
            totalTaxes = product.taxes.reduce((acc, tax) => {
              return acc + subtotal * (tax.percentage / 100)
            }, 0)
          }

          return formatCurrencyForCalculations(subtotal + totalTaxes)
        })
        .reduce((acc, bill) => formatCurrencyForCalculations(Big(bill).plus(acc)))

      return formatCurrencyForCalculations(
        Big(sumOfPriceWithDiscount).minus(sumOfPriceWithCouponAndDiscount)
      )
    }

    return 0
  },
  paymentMethod(state) {
    return state.paymentMethod
  },
  shippingMethod(state) {
    return state.shippingMethod
  }
}

export default getters
